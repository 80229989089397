interface AnyObject {
  [key: string]: any;
}

function isSimilar(value1: string, value2: string): boolean {
  let actualValue1 = value1;
  let actualValue2 = value2;
  if (value1.startsWith('/')) {
    actualValue1 = value1.slice(1);
  }
  if (value2.startsWith('/')) {
    actualValue2 = value2.slice(1);
  }
  if (actualValue1 === actualValue2) {
    return true;
  }
  return false;
}

export function findInNestedArray(
  arr: AnyObject[],
  targetKey: string,
  targetValue: string
): AnyObject | null {
  for (const element of arr) {
    if (typeof element === 'object' && !Array.isArray(element)) {
      if (element[targetKey] && isSimilar(element[targetKey], targetValue)) {
        return element;
      }
      if (element.children && Array.isArray(element.children)) {
        const result = findInNestedArray(
          element.children,
          targetKey,
          targetValue
        );
        if (result) {
          return result;
        }
      }
    }
  }
  return null;
}
