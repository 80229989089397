import { notification, Typography } from 'antd';
import NotificationDuration from './NotificationAutoClose';
import './style/ShowToastNotificationResult.less';
import React from 'react';

interface ShowToastNotificationResultProps {
  type: 'error' | 'warning' | 'success' | 'confirm' | 'info';
  title: string;
  subtitle?: string | React.ReactNode;
  autoClose?: boolean;
  autoCloseSecond?: number;
  afterClose?: () => void;
}

const ShowToastNotificationResult: React.FC<
  ShowToastNotificationResultProps
> = ({
  type,
  title,
  subtitle,
  autoClose = false,
  autoCloseSecond = 3,
  afterClose,
}) => {
  const isAutoClose = autoClose;
  let isClosed = false;

  const onClose = () => {
    if (!isClosed) {
      if (typeof afterClose === 'function' && afterClose) {
        afterClose();
      }
      if (type == 'success' || 'error') {
        notification.destroy('info-Generating file'); //for Payroll Inquiry
      }
      isClosed = type == 'info' ? false : true;
    }
  };

  if (isAutoClose) {
    window.setTimeout(() => {
      onClose();
    }, 500);
  }

  notification?.[type]({
    key: `${type}-${subtitle}`,
    message: <Typography.Text strong>{title}</Typography.Text>,
    description: (
      <>
        {typeof subtitle === 'string' ? (
          <div dangerouslySetInnerHTML={{ __html: subtitle }} />
        ) : (
          subtitle
        )}

        {isAutoClose && (
          <NotificationDuration autoCloseSecond={autoCloseSecond} type={type} />
        )}
      </>
    ),
    duration: isAutoClose ? autoCloseSecond + 0.1 : 0,
    className: `show-modal-result-toast ${type}`,
    styled: true,
    placement: 'top',

    ...(!isAutoClose && {
      onClose: () => {
        onClose();
      },
    }),
  });

  return null;
};
export default ShowToastNotificationResult;
