import { Button, Form, Input, Modal, Space, Spin } from 'antd';
import { LeftOutlined, LockOutlined } from '@ant-design/icons';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DATE_TIME_SEC_YYYY_MM_DD_DASH } from '@/lib/helpers/constant';
import { LANG_CODE } from '@/lib/helpers/constant';
import ShowToastNotificationResult from '@/lib/components/show-toast-notification-result/ShowToastNotificationResult';
import axiosInstance from '@/lib/helpers/axiosInstance';
import dayjs from 'dayjs';
import { setShowModalReLogin } from '@/lib/redux/reducers/globalReducer';
import styled from 'styled-components';
import useCookies from '@/lib/hooks/useCookies';
import { useTranslate } from 'lib/hooks/useResourceKey';

type CustomLayoutProps = {
  children: JSX.Element;
};

type ResponseLogin = {
  DATA: LoginData;
  ERRORS: [];
  REQUEST_TIME: string;
  STATUS: boolean;
  HSTATUS: number;
  ResponseJson?: {
    message: string;
    errors: string[];
    requestTime: string;
    traceID: string;
  };
};

type LoginData = {
  accessToken: string;
  refreshToken: string;
  accessTokenExpired: number;
  refreshTokenExpired: number;
  intervalDuration: number;
  currentOU: string;
  currentRole: string;
  pinType: string;
  isForceCreatePin: boolean;
  fssid: string; // For send request to Humatrix API or render Humatrix page
};

type GlobalState = {
  global: {
    refreshTokenLoading: boolean;
    refreshTokenSuccess: boolean | undefined;
    showModalReLogin: boolean;
    lastInteractive: boolean;
  };
};

type FormLoginValue = {
  username: string;
  password: string;
  isRemember?: boolean;
};

type ResponseLoginError = {
  MESSAGE: string;
  DATA: {
    message: string;
    hasBlocker: boolean;
    blockerToken: string;
    isForceChange: boolean;
    isAcceptancePolicy: boolean;
  };
  ERRORS: string[];
  REQUEST_TIME: string;
  STATUS: boolean;
  HSTATUS: number;
};

const ExpiryFormModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .modal-expiry__form-text {
    display: block;
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }

  .modal-expiry__form-password-wrapper {
    display: flex;
    margin-top: 24px;
    gap: 8px;
  }

  .modal-expiry__form-field {
    margin-right: 8px;
  }
`;

const ModalReLogin: React.FC<CustomLayoutProps> = ({ children }) => {
  const { getCookies, createCookies, deleteCookies } = useCookies();
  const manageCookie = getCookies('manage', true);
  const reduxContainer = useSelector(
    (rdxState: GlobalState) => rdxState.global
  );
  const [isLoginLoading, setLoginLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const sessionExpired = useTranslate(
    'SessionHasExpired',
    'Sorry, your access session has expired'
  );
  const titleError = useTranslate('Error', 'Error');

  const handleSubmit = async (values: { password: string }) => {
    try {
      setLoginLoading(true);
      const requestBody = {
        userid: manageCookie.username,
        password: values.password,
      };

      const response: AxiosResponse<ResponseLogin> = await axios({
        baseURL: `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/auth/login`,
        method: 'POST',
        responseType: 'json',
        data: requestBody,
        headers: {
          Language: LANG_CODE(),
          'Content-Type': 'application/json',
        },
      });

      if (response && response.data) {
        const data = response.data;

        if (data.DATA) {
          const accountName = {
            username: manageCookie.username,
            password: values.password,
            isRemember: manageCookie.isRemember,
          };

          onLoginSuccess(data.DATA, accountName);
          dispatch(setShowModalReLogin(false));
        } else if (data.ResponseJson) {
          ShowToastNotificationResult({
            type: 'error',
            title: titleError,
            subtitle: data.ResponseJson.message,
            autoClose: true,
            autoCloseSecond: 2,
          });
        }
      }
    } catch (e) {
      const error = e as AxiosError<ResponseLoginError>;
      if (error.response) {
        ShowToastNotificationResult({
          type: 'error',
          title: titleError,
          subtitle: error.response?.data.MESSAGE,
          autoClose: true,
          autoCloseSecond: 2,
        });
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const logoutAccount = async () => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/security/logout`
      );
      if (data.STATUS) {
        clearCookie();
        window.location.href = `${window.location.origin}/auth`;
      } else {
        clearCookie();
        window.location.href = `${window.location.origin}/auth`;
      }
    } catch (error) {
      clearCookie();
      window.location.href = `${window.location.origin}/auth`;
      throw error;
    }
  };

  const clearCookie = () => {
    deleteCookies('main');
    deleteCookies('jwtToken');
    deleteCookies('refreshToken');

    if (manageCookie?.isRemember) {
      const updateCookie = {
        isRemember: true,
        username: manageCookie?.username,
        password: manageCookie?.password,
      };
      createCookies('manage', updateCookie);
    } else {
      deleteCookies('manage');
    }
  };

  const onLoginSuccess = (
    loginResponse: LoginData,
    accountName: FormLoginValue
  ) => {
    const tokenExpired: string = dayjs
      .unix(loginResponse.accessTokenExpired)
      .format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
    const refreshTokenExpired: string = dayjs
      .unix(loginResponse.refreshTokenExpired)
      .format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
    const current: string = dayjs()
      .utc(true)
      .format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
    const expired: number = dayjs(tokenExpired).diff(current, 'day', true);
    const expiredRefresh: number = dayjs(refreshTokenExpired).diff(
      current,
      'day',
      true
    );

    createCookies(
      'jwtToken',
      {
        token: loginResponse.accessToken,
        fssid: loginResponse.fssid,
      },
      true,
      { expires: expired }
    );

    createCookies(
      'manage',
      {
        expired: loginResponse.accessTokenExpired,
        intervalDuration: loginResponse.intervalDuration,
        username: accountName.username,
        password: accountName.password,
        isRemember: accountName.isRemember ? accountName.isRemember : false,
      },
      true
    );

    createCookies(
      'refreshToken',
      {
        refreshToken: loginResponse.refreshToken,
      },
      true,
      { expires: expiredRefresh, secure: true }
    );

    createCookies('main', {
      currentOU: loginResponse.currentOU,
      currentRole: loginResponse.currentRole,
      loginResult: {
        languageList: ['en', 'id', 'th'],
      },
      pinType: loginResponse.pinType,
      isForceCreatePin: loginResponse.isForceCreatePin, //true,
    });
    window.location.reload();
  };

  return (
    <>
      <div
        style={{
          filter: `${reduxContainer.showModalReLogin ? 'blur(10px)' : 'unset'}`,
        }}
      >
        {children}
      </div>
      <Modal
        title={
          <Space>
            <LockOutlined style={{ fontSize: 16, fontWeight: 'bold' }} />
            <span>{useTranslate('AccessRelogin', 'Access Relogin')}</span>
          </Space>
        }
        closable={false}
        footer={[
          <Button
            type="link"
            key="Logout"
            icon={<LeftOutlined />}
            loading={isLoginLoading}
            onClick={() => logoutAccount()}
          >
            {useTranslate('Logout', 'Logout')}
          </Button>,
        ]}
        centered
        open={reduxContainer.showModalReLogin}
      >
        <Spin spinning={isLoginLoading}>
          <ExpiryFormModal>
            <div className="modal-expiry__form-text">
              <p>{sessionExpired}</p>
              <p>
                {useTranslate(
                  'PleaseInputPassword',
                  'Please input your password'
                )}
              </p>
            </div>

            <Form
              onFinish={handleSubmit}
              className="modal-expiry__form-password-wrapper "
            >
              <Form.Item name="password">
                <Input.Password className="modal-expiry__form-field" />
              </Form.Item>
              <Button htmlType="submit">
                {useTranslate('Submit', 'Submit')}
              </Button>
            </Form>
          </ExpiryFormModal>
        </Spin>
      </Modal>
    </>
  );
};

export default memo(ModalReLogin);
