import axios from 'axios';
import dayjs from 'dayjs';
import { Dispatch } from 'redux';
import { store } from '../storeExportType';
import useCookies from '../../hooks/useCookies';
import { DATE_TIME_SEC_YYYY_MM_DD_DASH } from '../../helpers/constant';
import {
  setRefreshTokenLoading,
  setRefreshTokenSuccess,
  setShowModalReLogin,
  setLastInteractive,
} from '../reducers/globalReducer';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const RefreshToken =
  (onClearInterval?: () => void, lastInteractive: boolean = true) =>
  async (dispatch: Dispatch) => {
    const state = store.getState();
    if (state.global.refreshTokenLoading === false) {
      dispatch(setRefreshTokenLoading(true));
      dispatch(setRefreshTokenSuccess(undefined));
      const { deleteCookies, getCookies, createCookies } = useCookies();
      try {
        const tokenCookie = getCookies('jwtToken', true);
        const refreshTokenCookie = getCookies('refreshToken', true);
        const manageCookie = getCookies('manage', true);
        if (!tokenCookie) {
          dispatch(setShowModalReLogin(true));
          return;
        }

        const response = await axios({
          method: 'post',
          url: `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/auth/refresh-token`,
          data: {
            refreshToken: refreshTokenCookie?.refreshToken,
            lastInteractive: lastInteractive,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response?.data) {
          const tokenExpired: string = dayjs
            .unix(response?.data.DATA.accessTokenExpired)
            .format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
          const refreshTokenExpired: string = dayjs
            .unix(response?.data.DATA.refreshTokenExpired)
            .format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
          const current: string = dayjs()
            .utc(true)
            .format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
          const expired: number = dayjs(tokenExpired).diff(
            current,
            'day',
            true
          );
          const expiredRefresh: number = dayjs(refreshTokenExpired).diff(
            current,
            'day',
            true
          );

          createCookies(
            'jwtToken',
            {
              token: response?.data.DATA.accessToken,
              fssid: response?.data.DATA.fssid,
            },
            true,
            { expires: expired }
          );

          const updateCookie = {
            ...manageCookie,
            expired: response?.data.DATA.accessTokenExpired,
          };

          createCookies('manage', updateCookie);

          createCookies(
            'refreshToken',
            {
              refreshToken: response?.data.DATA.refreshToken,
            },
            true,
            { expires: expiredRefresh, secure: true }
          );
          dispatch(setRefreshTokenSuccess(true));
        } else {
          deleteCookies('main');
          deleteCookies('manage');
          deleteCookies('jwtToken');
          deleteCookies('refreshToken');
          window.location.href = `${window.location.origin}/auth`;
        }
      } catch (e: any) {
        if (e.response?.status === 401) {
          if (onClearInterval) {
            onClearInterval();
          } else {
            dispatch(setShowModalReLogin(true));
          }
        } else {
          deleteCookies('main');
          deleteCookies('manage');
          deleteCookies('jwtToken');
          deleteCookies('refreshToken');
          deleteCookies('manage');

          window.location.href = `${window.location.origin}/auth`;
        }
      } finally {
        dispatch(setLastInteractive(false));
        dispatch(setRefreshTokenLoading(false));
      }
    }
  };
