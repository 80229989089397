import React from 'react';
import { useResourceKey } from '../../hooks/useResourceKey';
import { I18nextProvider, useTranslation } from 'react-i18next';
import languageInstance from '../../i18n';

const ResourceDefinitionProvider: React.FC<
  React.PropsWithChildren<{ module: string }>
> = ({ children, module }) => {
  const {
    i18n: { language, addResources, hasResourceBundle },
  } = useTranslation();
  const { fetchResourceKey, getResourceKeys, isExpiredResourceKey } =
    useResourceKey();

  const manageResourceKeys = async () => {
    const resourceKeys = await getResourceKeys();

    const shouldFetchResourceKeys = !resourceKeys || isExpiredResourceKey();

    if (shouldFetchResourceKeys) {
      localStorage.removeItem('hmc_resource');
      const newResourceKeys = await fetchResourceKey().catch(
        () => resourceKeys || {}
      );
      addResources(language, 'translation', newResourceKeys).changeLanguage(
        language
      );
    } else if (resourceKeys && !hasResourceBundle(language, 'translation')) {
      addResources(language, 'translation', resourceKeys).changeLanguage(
        language
      );
    }

    if (module === 'container') {
      fetchResourceKey();
    }
  };

  React.useEffect(() => {
    manageResourceKeys();
  }, [language]);

  return <I18nextProvider i18n={languageInstance}>{children}</I18nextProvider>;
};

export default ResourceDefinitionProvider;
