import { Flex, Form, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { BaseResponse } from '@/lib/interfaces';
import ChangePasswordForm from '../change-password-form';
import { LockSVG } from '../../dynamic-icon';
import { LoginPinContext } from '../login-pin/context/LoginPinContext';
import { PasswordValidationSetting } from '../password-validator/PasswordValidator';
import SecurityModal from '../security-modal';
import ShowToastNotificationResult from '../../show-toast-notification-result/ShowToastNotificationResult';
import WPForm from '../../wp-form/WPForm';
import axiosInstance from '@/lib/helpers/axiosInstance';
import changePasswordImage from '@/lib/assets/img/wp-change-pass-illustration.png';
import { useTranslate } from '@/lib/hooks/useResourceKey';

const ForceChangePinModal: React.FC = () => {
  const [FormInstance] = Form.useForm();
  const formType = 'force';
  const { isForceChangePinVisible, setIsForceChangePinVisible, oldPin } =
    useContext(LoginPinContext);

  // Translation hooks
  const labelForceChangePin = useTranslate(
    'ForceChangePin',
    'Force Change Pin'
  );
  const labelSubmit = useTranslate('Submit', 'Submit');
  const labelCancel = useTranslate('Cancel', 'Cancel');
  const labelError = useTranslate('Error', 'Error');
  const labelFailedToFetchData = useTranslate(
    'FailedToFetchData',
    'Failed to fetch data'
  );
  const labelNewPin = useTranslate('NewPin', 'New Pin');
  const labelConfirmPin = useTranslate('ConfirmPin', 'Confirm Pin');
  const labelSuccess = useTranslate('Success', 'Success');
  const labelPasswordChanged = useTranslate(
    'PasswordChanged',
    'Your PIN has been change successfully'
  );
  const labelFailToSubmitData = useTranslate(
    'FailToSubmitData',
    'Failed to submit data'
  );

  // State hooks
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [pinValidationSetting, setPinValidationSetting] =
    useState<PasswordValidationSetting>();

  // Fetch PIN validation settings on component mount
  useEffect(() => {
    const getPinValidationSetting = async () => {
      try {
        setIsLoading(true);
        const response: AxiosResponse<BaseResponse<PasswordValidationSetting>> =
          await axiosInstance.get('v1/hrm/security/pin-policy');
        setPinValidationSetting(response.data.DATA);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsForceChangePinVisible(false);
        ShowToastNotificationResult({
          type: 'error',
          title: labelError,
          subtitle: labelFailedToFetchData,
          autoClose: true,
          autoCloseSecond: 3,
        });
      }
    };

    getPinValidationSetting();
  }, [labelError, labelFailedToFetchData, setIsForceChangePinVisible]);

  // Handle form submission
  const onSubmit = async () => {
    try {
      await FormInstance.validateFields();
    } catch (validationError) {
      // Handle form validation error
      return; // Exit the function if validation fails
    }
    try {
      setIsSubmitLoading(true);
      await axiosInstance.post('v1/hrm/security/force-change/pin', {
        ...FormInstance.getFieldsValue(),
        oldPin,
      });
      ShowToastNotificationResult({
        type: 'success',
        title: labelSuccess,
        subtitle: labelPasswordChanged,
        autoClose: true,
        autoCloseSecond: 3,
      });
      onCancel();
      setIsSubmitLoading(false);
    } catch (error) {
      ShowToastNotificationResult({
        type: 'error',
        title: labelError,
        subtitle: labelFailToSubmitData,
        autoClose: true,
        autoCloseSecond: 3,
      });
    }
  };

  // Handle modal cancel action
  const onCancel = () => {
    setIsForceChangePinVisible(false);
    FormInstance.resetFields();
  };

  return (
    <SecurityModal
      title={
        <Flex align="middle" gap={'small'}>
          <LockSVG /> <Typography>{labelForceChangePin}</Typography>
        </Flex>
      }
      isVisible={isForceChangePinVisible}
      isLoading={isLoading}
      onSubmit={onSubmit}
      submitButtonText={labelSubmit}
      isCloseable={true}
      isSubmitLoading={isSubmitLoading}
      onCancel={onCancel}
      imageUrl={changePasswordImage}
    >
      <WPForm form={FormInstance} onFinish={onSubmit} layout="vertical">
        <ChangePasswordForm
          newPasswordLabel={labelNewPin}
          confirmPasswordLabel={labelConfirmPin}
          formType={formType}
          passwordValidationSetting={
            pinValidationSetting || ({} as PasswordValidationSetting)
          }
          securitySetting="PIN"
        />
      </WPForm>
    </SecurityModal>
  );
};

export default ForceChangePinModal;
