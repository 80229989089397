import { Flex, Grid, Spin } from 'antd';
import React, { useEffect } from 'react';
import { ResponseLogin, ResponseLoginError } from '../types/loginType';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomLayoutStyled } from '../themes/CustomLayoutStyled';
import ShowToastNotificationResult from '@/lib/components/show-toast-notification-result/ShowToastNotificationResult';
import { handleCallback } from '../components/OAuth/services/OAuthService';
import { useLoginFormContextCxt } from '../contexts/LoginFormContext';
import { useTranslate } from '@/lib/hooks/useResourceKey';

const OAuthCallback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const screenBreakPoints = Grid.useBreakpoint();
    const { onLoginSuccess, setBlockerToken, setIsAcceptancePolicy } = useLoginFormContextCxt();
    const labelError = useTranslate('Error', 'Error');

    useEffect(() => {
        const processOAuthCallback = async () => {
            try {
                const { authCode, client_id } = await handleCallback();

                if (!authCode || !client_id) {
                    navigate('/auth');
                    return;
                }

                const response: AxiosResponse<ResponseLogin> = await axios.post(
                    `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/auth/exchange-oauth-token`,
                    {
                        authCode: authCode,
                        clientId: client_id,
                        platform: 'WEB',
                    }
                );
                onLoginSuccess(response.data.DATA, { username: '', password: '' });
                window.location.href = `${process.env.VITE_APP_VIRTUAL_PATH || ''}/standard/home`;
            } catch (e: any) {
                const error = e as AxiosError<ResponseLoginError>;
                if (error.status === 401 && error.response?.data?.DATA?.hasBlocker !== undefined) {
                    setBlockerToken(error.response?.data?.DATA?.blockerToken);
                    setIsAcceptancePolicy(error.response?.data?.DATA?.isAcceptancePolicy);
                } else {
                    ShowToastNotificationResult({
                        type: 'error',
                        title: labelError,
                        subtitle: error.response?.data.MESSAGE || e.MESSAGE,
                        autoClose: true,
                        autoCloseSecond: 3,
                    });
                }

                navigate('/auth');
            }
        };

        processOAuthCallback();
    }, []);

    return (
        <CustomLayoutStyled className="WP-login" {...screenBreakPoints}>
            <Flex justify="center" align="center" style={{ height: '100vh' }}>
                <Spin size="large"></Spin>
            </Flex>
        </CustomLayoutStyled>
    );
};

export default OAuthCallback;
