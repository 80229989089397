import { setLastInteractive, setShowModalReLogin } from '@/lib/redux/reducers/globalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

import AuthContext from './useAuth';
import { DATE_TIME_SEC_YYYY_MM_DD_DASH } from '@/lib/helpers/constant';
import JSCookies from 'js-cookie';
import { RefreshToken } from '@/lib/redux/actions/globalAction';
import axiosInstance from '@/lib/helpers/axiosInstance';
import dayjs from 'dayjs';
import { decrypt } from '@/lib/helpers/cryptography';
import useCookies from '@/lib/hooks/useCookies';
import { useIdleTimer } from 'react-idle-timer';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface GlobalState {
    global: {
        refreshTokenLoading: boolean;
        refreshTokenSuccess: boolean | undefined;
        showModalReLogin: boolean;
        lastInteractive: boolean;
    };
}

const timeoutValue = 250;

export default function AuthProvider({ children }) {
    const dispatch = useDispatch();
    const currentIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const currentIntervalValidateSession = useRef<NodeJS.Timeout | null>(null);
    const inactivityTimeoutRef = useRef<number | null>(null);
    const { getCookies, deleteCookies, createCookies } = useCookies();
    const manageCookie = getCookies('manage', true);

    const maxTimeout = useMemo<string>(() => dayjs.unix(manageCookie?.expired).format(DATE_TIME_SEC_YYYY_MM_DD_DASH), [manageCookie?.expired]);
    const calcTimeout = useMemo<number>(
        () => dayjs(maxTimeout).diff(dayjs().utc(true).format(DATE_TIME_SEC_YYYY_MM_DD_DASH), 'second', true),
        [maxTimeout]
    );
    const msTimeout = useMemo<number>(() => calcTimeout, [calcTimeout]);

    const reduxContainer = useSelector((rdxState: GlobalState) => rdxState.global);

    const [timeInterval, setTimeInterval] = useState<number>(msTimeout);
    const [asyncRefreshToken, setAsyncRefreshToken] = useState<boolean>(true);
    const [isLoginLoading, setLoginLoading] = useState<boolean>(false);

    const { getRemainingTime } = useIdleTimer({
        timeout: manageCookie.intervalDuration,
        onIdle: () => dispatch(setLastInteractive(false)),
        onActive: () => dispatch(setLastInteractive(true)),
    });

    const calcExpiredTime = () => {
        const jwtExpired = decrypt({ value: JSCookies.get('manage') });

        const intervalDuration = jwtExpired.intervalDuration;
        const jwtExpiredTime = dayjs.unix(jwtExpired.expired).format(DATE_TIME_SEC_YYYY_MM_DD_DASH);

        if ((!jwtExpiredTime || !dayjs(jwtExpiredTime).isValid()) && !reduxContainer.showModalReLogin) {
            fetchRefreshToken();
            return calcExpiredTime();
        }

        const currentTime: string = dayjs().utc(true).format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
        const remainingTime = dayjs(jwtExpiredTime).diff(currentTime, 'second', true);

        return { jwtExpiredTime, remainingTime, intervalDuration };
    };

    const isExpired = () => {
        const { remainingTime, intervalDuration } = calcExpiredTime();
        const refreshTime = intervalDuration > 600000 ? 600 : 60;
        const isTimeExpired = remainingTime <= refreshTime;
        if (reduxContainer.showModalReLogin) {
            return false;
        }
        return isTimeExpired;
    };

    const onShowModalReLogin = () => {
        onClearInterval();
        dispatch(setShowModalReLogin(true));
    };

    const checkRefreshToken = () => {
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
        }
        inactivityTimeoutRef.current = window.setTimeout(() => {
            if (isExpired()) {
                setTimeInterval(msTimeout);
                fetchRefreshToken();
            }
        }, timeoutValue);
    };

    const onClearInterval = () => {
        if (currentIntervalRef?.current) {
            clearInterval(currentIntervalRef?.current);
        }
        if (currentIntervalValidateSession?.current) {
            clearInterval(currentIntervalValidateSession?.current);
        }
        setTimeInterval(msTimeout);
    };

    const fetchRefreshToken = async () => {
        if (!reduxContainer.showModalReLogin && asyncRefreshToken) {
            setAsyncRefreshToken(false);
            const lastInteractive = getRemainingTime() > 0 ? true : false;
            RefreshToken(onShowModalReLogin, lastInteractive)(dispatch);
        }
    };

    const onIntervalHandler = () => {
        if (timeInterval > 0) {
            checkRefreshToken();
            setTimeInterval((prevState) => prevState - 1000);
            return;
        }
        setTimeInterval(msTimeout);
        fetchRefreshToken();
    };

    const runtimeInterval = () => {
        if (calcTimeout < 0) {
            checkRefreshToken();
            return;
        }
        currentIntervalRef.current = setInterval(() => {
            onIntervalHandler();
        }, manageCookie.intervalDuration);
    };

    const feValidateSession = () => {
        currentIntervalValidateSession.current = setInterval(async () => {
            try {
                const { data } = await axiosInstance.get(`${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/security/validate-session`);
                if (data.HSTATUS === 401) {
                    onShowModalReLogin();
                }
            } catch (error: any) {
                if (error.HSTATUS === 401 || error.status === 401) {
                    onShowModalReLogin();
                }
            }
        }, manageCookie.intervalDuration);
    };
    useEffect(() => {
        if (reduxContainer.showModalReLogin) {
            onClearInterval();
        }
    }, [reduxContainer.showModalReLogin]);

    useEffect(() => {
        runtimeInterval();
        feValidateSession();
        return () => {
            if (currentIntervalRef.current) {
                clearInterval(currentIntervalRef.current);
            }
            if (currentIntervalValidateSession.current) {
                clearInterval(currentIntervalValidateSession.current);
            }
        };
    }, []);

    const logoutAccount = async () => {
        try {
            const { data } = await axiosInstance.post(`${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/security/logout`);
            if (data.STATUS) {
                clearCookie();
                window.location.href = `${window.location.origin}/auth`;
            } else {
                clearCookie();
                window.location.href = `${window.location.origin}/auth`;
            }
        } catch (error) {
            clearCookie();
            window.location.href = `${window.location.origin}/auth`;
            throw error;
        }
    };

    const clearCookie = () => {
        deleteCookies('main');
        deleteCookies('jwtToken');
        deleteCookies('refreshToken');

        if (manageCookie?.isRemember) {
            const updateCookie = {
                isRemember: true,
                username: manageCookie?.username,
                password: manageCookie?.password,
            };
            createCookies('manage', updateCookie);
        } else {
            deleteCookies('manage');
        }
    };

    const dispatchIdleTimer = useMemo(
        () => ({
            onClearInterval: () => onClearInterval(),
            setAsyncRefreshToken: (payload: boolean) => setAsyncRefreshToken(payload),
            setLoginLoading: (payload: boolean) => setLoginLoading(payload),
            onShowModalReLogin: () => onShowModalReLogin(),
            runtimeInterval: () => runtimeInterval(),
        }),
        []
    );

    return (
        <AuthContext.Provider
            value={{
                isLoginLoading: isLoginLoading,
                isModalOpen: reduxContainer.showModalReLogin,
                dispatchIdleTimer: dispatchIdleTimer,
                logoutAccount: logoutAccount,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
