import React, { useEffect, useState } from 'react';
import { CustomLayoutStyled } from '../../../auth/login/themes/CustomLayoutStyled';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Grid, Row, Card, Input, Button, Typography, Form, Spin, Image } from 'antd';

import { useResourceKey, useTranslate } from '@/lib/hooks/useResourceKey';
import { useEmailApprovalContext } from '../context/EmailApprovalContextProvider';
import { FormEmailApprovalValue, RequestBodyEmailApproval, ResponseEmailApproval } from '../types/emailApproval';
import WPForm from '@/lib/components/wp-form/WPForm';
import axios, { AxiosResponse } from 'axios';
import { LANG_CODE } from '@/helpers/Constants';
import useSwitchLanguage from '@/hooks/useSwitchLanguage';
import getClientLogo from '@/lib/components/image-handler/getClientLogo';
import WPZIcon from '@/lib/components/wpz-icon/WPZIcon';
import LanguageSelector from '@/components/global/lang/LangSelector';
import styled from 'styled-components';

const { Text } = Typography;

const { TextArea } = Input;

const Wrapper = styled.div`
    width: 100%;
    max-width: 572px;
    height: auto;
    position: relative;
    margin: 0 auto;
    padding: 16px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 8px;
    }
`;
const ResultWrapper = styled.div`
    width: 100%;
    max-width: 448px;
    height: auto;
    position: relative;
    margin: 0 auto;
    padding: 16px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 8px;
    }
`;

export default function EmailApproval() {
    const { form, onEmailApprovalSuccess } = useEmailApprovalContext();
    const screenBreakPoints = Grid.useBreakpoint();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const submitActionApprove = 'APR';
    const submitActionReject = 'REJ';
    const submitActionCancel = 'CNL';

    const possibleStatus = {
        success: 'success',
        error: 'error',
        warning: 'warning',
    };

    const emailApprovalResourceKey = {
        EmailApproval: useTranslate('EmailApproval', 'approval'),
        EmailReject: useTranslate('EmailReject', 'reject'),
        EmailApprovalTitleError: useTranslate('EmailApprovalTitleError', 'Error'),
        EmailApprovalTitleSuccess: useTranslate('EmailApprovalTitleSuccess', 'Success'),
        EmailApprovalTitleWarning: useTranslate('EmailApprovalTitleWarning', 'Warning'),
        EmailApprovalApprovedMessage: useTranslate('EmailApprovalApprovedMessage', 'The request has been approved'),
        EmailApprovalRejectedMessage: useTranslate('EmailApprovalRejectedMessage', 'The request has been rejected'),
        EmailApprovalPendingApprovalMessage: useTranslate('EmailApprovalPendingApprovalMessage', 'The request is pending approval'),
        EmailApprovalPendingRejectedMessage: useTranslate('EmailApprovalPendingRejectedMessage', 'The request is pending rejected'),
        EmailApprovalConfirmation: useTranslate('EmailApprovalConfirmation', 'Confirmation'),
        EmailApprovalConfirmationApproveDesc: useTranslate('EmailApprovalConfirmationApproveDesc', 'Please confirm the approval of this request'),
        EmailApprovalConfirmationRejectDesc: useTranslate('EmailApprovalConfirmationRejectDesc', 'Please confirm the reject of this request'),
        EmailApprovalNote: useTranslate('EmailApprovalNote', 'Note'),
        EmailApprovalRequiredMarkMessage: useTranslate('EmailApprovalRequiredMarkMessage', 'Note is Required'),
        AnUnhandledErrorOccurred: useTranslate('AnUnhandledErrorOccurred', 'An unhandled error occurred'),
        Cancel: useTranslate('CNL', 'Cancel'),
        Confirm: useTranslate('BtnConfirm', 'Confirm'),
        Note: useTranslate('Note', 'Note'),
        Email: useTranslate('Email', 'This token has already activated, Please regenerating the OTP.'),
    };

    const [emailApprovalLoading, setEmailApprovalLoading] = useState<boolean>(false);
    const [isResultPage, setDisplayResultPage] = useState(false);
    const [isRequiredNote, setIsRequiredNote] = useState(false);
    const [logo, setLogo] = useState<string | null>(null);
    const [imageloading, setImageLoading] = useState<boolean>(true);
    const [resultTitle, setResultTitle] = useState<string>(emailApprovalResourceKey.EmailApprovalTitleWarning);
    const [resultDescription, setResultDescription] = useState<string>(emailApprovalResourceKey.EmailApprovalPendingApprovalMessage);
    const [status, setStatus] = useState<string>(submitActionApprove);
    const [paramLang, setParamLang] = useState<string>('');

    const reqBody: RequestBodyEmailApproval = {
        action: '',
        remark: '',
        ott: '',
        key: '',
        module: '',
    };

    const [requestBody, setRequestBody] = useState<RequestBodyEmailApproval>(reqBody);

    const fetchLogo = async () => {
        const logo = await getClientLogo();
        setLogo(logo);
        setImageLoading(false);
    };

    const requiredStyle = { display: 'inline-block', marginLeft: '4px', color: '#ff4d4f', fontSize: '12px', marginTop: '5px' };
    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            {label}
            {required && <span style={requiredStyle}>*</span>}
        </>
    );

    const getIcon = () => {
        if (status === possibleStatus.success) {
            return 'icon-success';
        } else if (status === possibleStatus.error) {
            return 'icon-error';
        } else {
            return 'icon-warning';
        }
    };

    const getIconColor = () => {
        if (status === possibleStatus.success) {
            return '#14C0BD';
        } else if (status === possibleStatus.error) {
            return '#F64E60';
        } else {
            return '#FEB719';
        }
    };

    const onEmailApprovalCancel = () => {
        setStatus(submitActionCancel);
        if (requestBody.action === submitActionApprove) {
            setResultTitle(emailApprovalResourceKey.EmailApprovalTitleWarning);
            setResultDescription(emailApprovalResourceKey.EmailApprovalPendingApprovalMessage);
        } else if (requestBody.action === submitActionReject) {
            setResultTitle(emailApprovalResourceKey.EmailApprovalTitleWarning);
            setResultDescription(emailApprovalResourceKey.EmailApprovalPendingRejectedMessage);
        }
        setDisplayResultPage(true);
    };

    const onEmailApprovalSubmit = async (values: FormEmailApprovalValue) => {
        try {
            setEmailApprovalLoading(true);
            const lang = searchParams.get('lang')?.toLowerCase();

            const body: RequestBodyEmailApproval = {
                action: requestBody.action,
                remark: values.remark,
                ott: requestBody.ott,
                key: requestBody.key,
                module: requestBody.module,
            };

            const response: AxiosResponse<ResponseEmailApproval> = await axios({
                baseURL: `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/approval/public/email-approval/submit`,
                method: 'POST',
                responseType: 'json',
                data: body,
                headers: {
                    'Content-Type': 'application/json',
                    language: lang,
                },
            });

            setEmailApprovalLoading(false);

            if (response.data.STATUS === true) {
                setStatus(possibleStatus.success);
                setResultTitle(emailApprovalResourceKey.EmailApprovalTitleSuccess);
                if (requestBody.action === submitActionApprove) {
                    setResultDescription(emailApprovalResourceKey.EmailApprovalApprovedMessage);
                } else {
                    setResultDescription(emailApprovalResourceKey.EmailApprovalRejectedMessage);
                }
            } else {
                setStatus(possibleStatus.error);
                setResultTitle(emailApprovalResourceKey.EmailApprovalTitleError);
                setResultDescription(response.data.DATA.description);
            }

            setDisplayResultPage(true);
        } catch (e) {
            setStatus(possibleStatus.error);
            setEmailApprovalLoading(false);
            setDisplayResultPage(false);
            setResultTitle(emailApprovalResourceKey.EmailApprovalTitleError);
            setResultDescription(emailApprovalResourceKey.AnUnhandledErrorOccurred);
        }
    };

    useEffect(() => {
        fetchLogo();

        const action = searchParams.get('action')?.toUpperCase();
        const ott = searchParams.get('ott');
        const key = searchParams.get('key');
        const module = searchParams.get('module');
        const lang = searchParams.get('lang')?.toLowerCase();

        setParamLang(lang || '');
        const emailApprovalRequestBody: RequestBodyEmailApproval = {
            action: action || '',
            ott: ott || '',
            key: key || '',
            module: module || '',
            remark: '',
        };

        setRequestBody(emailApprovalRequestBody);

        if (action === submitActionApprove) {
            setIsRequiredNote(false);
        } else {
            setIsRequiredNote(true);
        }

        setDisplayResultPage(false);
    }, []);

    return (
        <>
            {!isResultPage && (
                <WPForm form={form} layout="vertical" onFinish={onEmailApprovalSubmit} requiredMark={customizeRequiredMark}>
                    <div className="WP-login__header" style={{ display: 'none' }}>
                        <LanguageSelector withBorder={false} lang={paramLang} />
                    </div>
                    <CustomLayoutStyled className="WP-login" {...screenBreakPoints}>
                        <Wrapper>
                            <Card
                                title={emailApprovalResourceKey.EmailApprovalConfirmation}
                                actions={[
                                    <Col flex={'auto'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                        <Button
                                            style={{
                                                marginRight: '10px',
                                            }}
                                            type="default"
                                            onClick={onEmailApprovalCancel}
                                        >
                                            {emailApprovalResourceKey.Cancel}
                                        </Button>
                                        <Button htmlType="submit" style={{ marginRight: '20px' }} type="primary" loading={emailApprovalLoading}>
                                            {emailApprovalResourceKey.Confirm}
                                        </Button>
                                    </Col>,
                                ]}
                            >
                                <Row>
                                    <Text
                                        style={{
                                            display: 'block',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        {requestBody.action === submitActionApprove
                                            ? emailApprovalResourceKey.EmailApprovalConfirmationApproveDesc
                                            : emailApprovalResourceKey.EmailApprovalConfirmationRejectDesc}
                                    </Text>
                                </Row>
                                <Row
                                    align={'middle'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'nowrap',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Col flex="auto">
                                        <Form.Item
                                            style={{ fontSize: '12px' }}
                                            label={emailApprovalResourceKey.Note}
                                            name="remark"
                                            rules={[{ required: isRequiredNote, message: emailApprovalResourceKey.EmailApprovalRequiredMarkMessage }]}
                                        >
                                            <TextArea rows={2} maxLength={500} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Wrapper>
                    </CustomLayoutStyled>
                </WPForm>
            )}

            {isResultPage && (
                <CustomLayoutStyled className="WP-login" {...screenBreakPoints}>
                    <ResultWrapper>
                        <Card>
                            <Row justify="center" style={{ marginBottom: 56 }}>
                                <Col flex="auto" style={{ textAlign: 'center' }}>
                                    <Spin spinning={imageloading}>
                                        <Image
                                            style={{ marginTop: '20px' }}
                                            preview={false}
                                            className="image company-logo"
                                            alt=""
                                            src={logo ?? ''}
                                            width={184}
                                        />
                                    </Spin>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="auto" style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <WPZIcon iconName={getIcon()} style={{ fontSize: 58, color: getIconColor() }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="auto" style={{ textAlign: 'center' }}>
                                    <Text
                                        style={{
                                            display: 'block',
                                            marginBottom: '15px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {resultTitle}
                                    </Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex="auto" style={{ textAlign: 'center' }}>
                                    <Text
                                        style={{
                                            display: 'block',
                                            marginBottom: '15px',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {resultDescription}
                                    </Text>
                                </Col>
                            </Row>
                        </Card>
                    </ResultWrapper>
                </CustomLayoutStyled>
            )}
        </>
    );
}
