import { Navigate, Outlet, useLocation } from 'react-router-dom';

import LoadingComponent from '@/lib/components/loading/LoadingComponent';
import { PersistState } from '@/lib/redux/reducers/persistReducer';
import { Suspense } from 'react';
import { useStoreSelector } from '@/hooks/useStore';

const DEFAULT_PATH_LIST = ['/standard/home', '/standard/inbox'];

const WPAccess = () => {
    const { modeAccessList = [] } = useStoreSelector((state: any) => state.persist);
    const { pathname: currentPath } = useLocation();

    const isPathAccessible = (path: string) => {
        // Check if the path is in the default path list
        if (DEFAULT_PATH_LIST.some((defaultPath) => path.includes(defaultPath) || path === defaultPath)) {
            return true;
        }

        // Check if the path or its subpaths are in the mode access list

        return (modeAccessList as PersistState['modeAccessList']).some((item) => {
            const basePath = item.path.replace(/^\/|\/$/g, '');
            const currentBasePath = path.replace(/^\/|\/$/g, '');

            if (currentBasePath === basePath) {
                return true;
            }

            if (item.modeAccess.new && currentBasePath.startsWith(`${basePath}.add`)) {
                return true;
            }

            if (item.modeAccess.view && (currentBasePath.startsWith(`${basePath}.detail`) || currentBasePath.startsWith(`${basePath}.history`))) {
                return true;
            }

            return false;
        });
    };

    return (
        <>
            {isPathAccessible(currentPath) ? (
                <Suspense fallback={<LoadingComponent />}>
                    <Outlet />
                </Suspense>
            ) : (
                <Navigate to="/standard/home" />
            )}
        </>
    );
};

export default WPAccess;
