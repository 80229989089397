import { FormLoginValue, LoginData } from '../types/loginType';
import { createContext, useContext } from 'react';

import { FormInstance } from 'antd/es/form';

interface AuthContextType {
    isLoginLoading: boolean;
    onLoginSuccess: (loginResponse: LoginData, accountName: FormLoginValue) => void;
    form: FormInstance;
    blockerToken: string | null;
    setBlockerToken: (value: string | null) => void;
    isAcceptancePolicy: boolean;
    setIsAcceptancePolicy: (value: boolean) => void;
}

const contextInitialValue: AuthContextType = {
    isLoginLoading: false,
    onLoginSuccess: (loginResponse, accountName) => {},
    form: {} as FormInstance,
    blockerToken: null,
    setBlockerToken: (value) => {},
    isAcceptancePolicy: false,
    setIsAcceptancePolicy: (value) => {},
};

const LoginFormContext = createContext<AuthContextType>(contextInitialValue);

export const useLoginFormContextCxt = () => useContext(LoginFormContext);

export default LoginFormContext;
