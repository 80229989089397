export enum WPZIconNameEnum {
  'icon-moveWidget',
  'icon-descending',
  'icon-ascending',
  'icon-bookMarkFilled',
  'icon-csv',
  'icon-oth',
  'icon-txt',
  'icon-xls',
  'icon-addsemi',
  'icon-addToCart',
  'icon-airplay',
  'icon-alignCenter',
  'icon-alignJustified',
  'icon-alignLeft',
  'icon-alignRight',
  'icon-articleFilled',
  'icon-attendanceAdministration',
  'icon-attendanceBasePhoto',
  'icon-attendanceInquiry',
  'icon-autoGenerate',
  'icon-bank',
  'icon-bookmarkSemi',
  'icon-bottle',
  'icon-career',
  'icon-careerHistory',
  'icon-category',
  'icon-celebrate',
  'icon-certificated',
  'icon-chapter',
  'icon-claimInquiry',
  'icon-collapseMenu',
  'icon-compress',
  'icon-consent',
  'icon-creditCard',
  'icon-csvSmall',
  'icon-decreaseFont',
  'icon-deleteSemi',
  'icon-Doctor-and-Hospital-List',
  'icon-document',
  'icon-faceRecognize',
  'icon-fastForward10',
  'icon-fullScreen2',
  'icon-goodHealthandWellBeing',
  'icon-height',
  'icon-hotel',
  'icon-increaseFont',
  'icon-IndividualandCareerDevelopment',
  'icon-informationSemi',
  'icon-iTSoftware',
  'icon-lifelongLearning',
  'icon-mobileBanking',
  'icon-multiselectSemi',
  'icon-myReimbursement-Balance',
  'icon-myfavoriteCourseSelected',
  'icon-myfavoriteCourseUnselect',
  'icon-nationality',
  'icon-navigation',
  'icon-navigationFill',
  'icon-newJob',
  'icon-nonCharge',
  'icon-note',
  'icon-objectiveTracking',
  'icon-pageNumber',
  'icon-pauseTracking',
  'icon-payment',
  'icon-Payroll-Advance',
  'icon-pdf',
  'icon-pettyCash',
  'icon-playCircle',
  'icon-purchaseRequest',
  'icon-qa',
  'icon-quiz',
  'icon-readHorizon',
  'icon-readVertical',
  'icon-reCaptchaSemi',
  'icon-refreshSemi',
  'icon-resignation',
  'icon-rewind10',
  'icon-Role',
  'icon-safe',
  'icon-screenCast',
  'icon-searchSemi',
  'icon-settingFont',
  'icon-settingSemi',
  'icon-shareSemi',
  'icon-shoppingSemi',
  'icon-smoke',
  'icon-TasksFeedback',
  'icon-teammateLeave',
  'icon-teamProgress',
  'icon-unverified',
  'icon-viewSemi',
  'icon-weight',
  'icon-workAccident',
  'icon-x16Actual',
  'icon-x16AI1',
  'icon-x16AI2',
  'icon-x16AlignCenter',
  'icon-x16AlignJustified',
  'icon-x16AlignLeft',
  'icon-x16AlignRight',
  'icon-x16More',
  'icon-x16ReadHorizon',
  'icon-x16ReadVertical',
  'icon-x16TimeRecording',
  'icon-xLSSmall',
  'icon-authorization',
  'icon-downloadExport',
  'icon-uploadImport',
  'icon-moreSemi',
  'icon-role',
  'icon-editWidget',
  'icon-break',
  'icon-payrollAdvance',
  'icon-stop',
  'icon-signature',
  'icon-multipleRequest',
  'icon-singleRequest',
  'icon-doctorAndHospitalList',
  'icon-myReimbursementBalance',
  'icon-humanSpace',
  'icon-arrowRight',
  'icon-arrowLeft',
  'icon-basePhoto',
  'icon-workFromAnywhere',
  'icon-department',
  'icon-incharge',
  'icon-inchargeFilled',
  'icon-x16Incharge',
  'icon-completed2',
  'icon-noData',
  'icon-overdueTask',
  'icon-todayTask',
  'icon-achievementAnalysis',
  'icon-activityWork',
  'icon-initiative',
  'icon-percent',
  'icon-performanceReviewMenu',
  'icon-planned',
  'icon-progressAnalysis',
  'icon-progressTracking',
  'icon-project',
  'icon-task',
  'icon-authentication',
  'icon-focus',
  'icon-coupon',
  'icon-specialPrivilege',
  'icon-cremation',
  'icon-licence',
  'icon-privacyPolicy',
  'icon-compassionateLeave',
  'icon-maternityLeave',
  'icon-meditationLeave',
  'icon-ordinationLeave',
  'icon-parentalLeave',
  'icon-filterSemi',
  'icon-tapHold',
  'icon-undo',
  'icon-share',
  'icon-telephone',
  'icon-completed',
  'icon-fire',
  'icon-glasses',
  'icon-x16Edit',
  'icon-travel',
  'icon-lifeInsurance',
  'icon-startRecordTime',
  'icon-endRecordTime',
  'icon-company',
  'icon-movieTicket',
  'icon-uniform',
  'icon-gym',
  'icon-article',
  'icon-helpfulOutline',
  'icon-myActivityLog',
  'icon-myPost',
  'icon-poll',
  'icon-questionAndAnswer',
  'icon-x16HelpfulFilled',
  'icon-accidentInsurance',
  'icon-carLoan',
  'icon-classandWorkshopsForHealth',
  'icon-classesAndWorkshops',
  'icon-dentalCare',
  'icon-diseaseInsurance',
  'icon-homeLoan',
  'icon-personalLoan',
  'icon-vaccine',
  'icon-timesheetTemplate',
  'icon-microphone',
  'icon-endTracking',
  'icon-startTracking',
  'icon-x16Minus',
  'icon-transportation',
  'icon-personalActivity',
  'icon-workActivity',
  'icon-marketplaze',
  'icon-nutritionist',
  'icon-physiotherapy',
  'icon-telemedicineBooking',
  'icon-telemedicineOnDemand',
  'icon-x16HeartFilled',
  'icon-activityRecord',
  'icon-comment',
  'icon-feed',
  'icon-like',
  'icon-timer',
  'icon-timer3s',
  'icon-toggleDropdown',
  'icon-offlineMode',
  'icon-onlineMode',
  'icon-deletePopup',
  'icon-changeCompany',
  'icon-flash',
  'icon-noFlash',
  'icon-x16Add',
  'icon-x16Check',
  'icon-fingerprint',
  'icon-faceID',
  'icon-swap',
  'icon-completedRequest',
  'icon-storage',
  'icon-refresh',
  'icon-checkboxDisableDeselected',
  'icon-checkboxEnableDeselected',
  'icon-checkboxSelected',
  'icon-radioboxDisableDeselected',
  'icon-radioboxEnableDeselected',
  'icon-radioboxSelected',
  'icon-x16CheckboxDisableDeselected',
  'icon-x16CheckboxEnableDeselected',
  'icon-x16CheckboxSelected',
  'icon-x16RadioboxDisableDeselected',
  'icon-x16RadioboxEnableDeselected',
  'icon-x16RadioboxSelected',
  'icon-transactionSuccess',
  'icon-transactionError',
  'icon-upcoming',
  'icon-timezoneSetting',
  'icon-language',
  'icon-pinCode',
  'icon-documentPassword',
  'icon-community',
  'icon-filter',
  'icon-mission',
  'icon-myReward',
  'icon-projectSharing',
  'icon-ranking',
  'icon-rewards',
  'icon-shopping',
  'icon-starFilled',
  'icon-trophyFilled',
  'icon-onDutyRequest',
  'icon-applicant',
  'icon-cambodiaTaxReport',
  'icon-checkFilled',
  'icon-claimForm',
  'icon-compensationEvaluation',
  'icon-compensationPlaning',
  'icon-customRecruitment',
  'icon-employeesLoan',
  'icon-errorFilled',
  'icon-genericPayroll',
  'icon-loanReports',
  'icon-loanRequest',
  'icon-loanVerification',
  'icon-myPayrollInfo',
  'icon-paymentProcess',
  'icon-payrollCycle',
  'icon-payrolldataChangeRequest',
  'icon-payrollmasterData',
  'icon-payrollProcess',
  'icon-payrollReports',
  'icon-PerformanceCalibration',
  'icon-performanceManagementReports',
  'icon-processLoan',
  'icon-recruitmentDashboard',
  'icon-recruitmentReports',
  'icon-recruitmentRequest',
  'icon-reimbursementBalanceManagement',
  'icon-reimbursementInterfaceProcess',
  'icon-reimbursementReports',
  'icon-statutoryReports',
  'icon-taxCalculator',
  'icon-taxReport',
  'icon-objectiveKeyResult',
  'icon-trainingEvent',
  'icon-announcement',
  'icon-assignment',
  'icon-attendanceInterfaceProcess',
  'icon-attendanceList',
  'icon-awards',
  'icon-careerAdministrationReports',
  'icon-carrerTransition',
  'icon-companyPolicy',
  'icon-disciplines',
  'icon-employeeAttendanceLocation',
  'icon-employeeInformation',
  'icon-employeeLeaveGrade',
  'icon-employeeLetter',
  'icon-employeeReports',
  'icon-employeeShiftGroup',
  'icon-employeeSurvey',
  'icon-empoyeeDefinedReports',
  'icon-leaveBalanceManagement',
  'icon-leaveRequest',
  'icon-massLeave',
  'icon-myCalendar',
  'icon-ohsa',
  'icon-achievement',
  'icon-organizationReports',
  'icon-overtimeRequest',
  'icon-overtimeUpload',
  'icon-incompleteAttendanceData',
  'icon-resourceBooking',
  'icon-scheduleTaskLog',
  'icon-shiftSchedule',
  'icon-swapShiftRequest',
  'icon-timeAttendanceReports',
  'icon-timesheetRequest',
  'icon-warning',
  'icon-error',
  'icon-success',
  'icon-deletePasscode',
  'icon-night',
  'icon-afternoon',
  'icon-day',
  'icon-unfavorite',
  'icon-favorite',
  'icon-activity',
  'icon-add',
  'icon-addStd',
  'icon-analyticsReport',
  'icon-annualLeave',
  'icon-approval',
  'icon-asset',
  'icon-attach',
  'icon-attendanceManagement',
  'icon-back',
  'icon-backSemi',
  'icon-benefits',
  'icon-benefitSheet',
  'icon-birthday',
  'icon-birthdayLeaveWithoutPay',
  'icon-birthdayLeaveWithPay',
  'icon-blog',
  'icon-bookmark',
  'icon-calendar',
  'icon-calendarDatePicker',
  'icon-camera',
  'icon-chatsVideoConferences',
  'icon-claim',
  'icon-claimsAndBenefits',
  'icon-close',
  'icon-collaboration',
  'icon-compensation',
  'icon-dashboard',
  'icon-delete',
  'icon-deleteStd',
  'icon-documentRequest',
  'icon-down',
  'icon-download',
  'icon-downSemi',
  'icon-edit',
  'icon-editSemi',
  'icon-editSmall',
  'icon-attendanceDataChangeRequest',
  'icon-eLearning',
  'icon-email',
  'icon-userOutlined',
  'icon-employeeCard',
  'icon-employeeManagement',
  'icon-employeeMovement',
  'icon-employmentReminder',
  'icon-female',
  'icon-flexiBenefit',
  'icon-fullScreen',
  'icon-global',
  'icon-graduate',
  'icon-health',
  'icon-hide',
  'icon-home',
  'icon-individualandCareerDevelopment',
  'icon-information',
  'icon-learning',
  'icon-leave',
  'icon-leaveBalance',
  'icon-leaveWithoutPay',
  'icon-list',
  'icon-location',
  'icon-lock',
  'icon-loginActivityDevice',
  'icon-male',
  'icon-manpower',
  'icon-manpowerPlan',
  'icon-manualRegister',
  'icon-marriage',
  'icon-militaryServiceLeave',
  'icon-mobile',
  'icon-more',
  'icon-move',
  'icon-multiSelect',
  'icon-multiSelectSmall',
  'icon-myBalance',
  'icon-myDocument',
  'icon-myPlaze',
  'icon-myProfile',
  'icon-mySummaryPage',
  'icon-myTeam',
  'icon-myWeeklyCalendar',
  'icon-nearbyStores',
  'icon-necessaryBusinessLeave',
  'icon-newBorn',
  'icon-fundViewOutlined',
  'icon-next',
  'icon-nextSemi',
  'icon-noPreview',
  'icon-notification',
  'icon-objectiveSetting',
  'icon-offBoarding',
  'icon-offsiteWork',
  'icon-onboarding',
  'icon-onlineRecruitmentPortal',
  'icon-clusterOutlined',
  'icon-organizationStructure',
  'icon-overTime',
  'icon-passcode',
  'icon-payroll',
  'icon-paySlip',
  'icon-performance',
  'icon-performanceCalibration',
  'icon-performanceEvaluationForm',
  'icon-performanceManagement',
  'icon-performanceOKR',
  'icon-performancePeriod',
  'icon-performancePlanningForm',
  'icon-performanceReview',
  'icon-personalHealthRecord',
  'icon-pfStatement',
  'icon-photo',
  'icon-pin',
  'icon-planDayOff',
  'icon-preBoarding',
  'icon-preview',
  'icon-probation',
  'icon-projectManagement',
  'icon-providentFund',
  'icon-qrRegister',
  'icon-reCaptcha',
  'icon-recruitment',
  'icon-reminder',
  'icon-reoder',
  'icon-retire',
  'icon-scheduling',
  'icon-search',
  'icon-setting',
  'icon-shift',
  'icon-shiftExchange',
  'icon-showLess',
  'icon-showMore',
  'icon-sickLeave',
  'icon-singleSignOn',
  'icon-smartDoc',
  'icon-sorting',
  'icon-specialNecessaryBusinessLeave',
  'icon-sterilizationLeave',
  'icon-successionPlanning',
  'icon-summary',
  'icon-survey',
  'icon-switchCamera',
  'icon-talentManagement',
  'icon-tas19',
  'icon-tasksFeedback',
  'icon-taxAllowance',
  'icon-taxForm',
  'icon-teamAttendance',
  'icon-telePharmacy',
  'icon-thumbnail',
  'icon-clockCircleOutlined',
  'icon-ClockCircleOutlined',
  'icon-timeClockLocationMaster',
  'icon-timeclock',
  'icon-timeClockHistory',
  'icon-timeRequest',
  'icon-timesheet',
  'icon-trainingLeave',
  'icon-trainingManagement',
  'icon-unLock',
  'icon-unpin',
  'icon-up',
  'icon-upSemi',
  'icon-userGuide',
  'icon-userTimeClockLocationMapping',
  'icon-workflow',
  'icon-workFromHome',
  'icon-x16Back',
  'icon-x16Camera',
  'icon-x16Close',
  'icon-x16Down',
  'icon-x16Download',
  'icon-x16Next',
  'icon-x16Pin',
  'icon-x16Unpin',
  'icon-x16Up',
}

export type WPZIconName = keyof typeof WPZIconNameEnum;
