import React from 'react';
import { WPZIconName, WPZIconNameEnum } from './interface';

type WPZIconProps = {
  iconName: WPZIconName;
  style?: React.CSSProperties;
  className?: string;
  defaultIconName?: WPZIconName;
};

const WPZIcon: React.FC<WPZIconProps> = ({
  iconName,
  style,
  className = '',
  defaultIconName = '',
}) => {
  const iconNameWithPrefix = iconName.startsWith('icon-')
    ? iconName
    : `icon-${iconName}`;

  const isValidIconName = iconNameWithPrefix in WPZIconNameEnum;

  const displayIcon = isValidIconName ? iconNameWithPrefix.toLowerCase() : defaultIconName.toLowerCase();

  return (
    <span
      role="img"
      aria-label={displayIcon}
      className={`${displayIcon} currentColor ${className}`}
      style={{ display: 'block', ...(style && style) }}
    />
  );
};

export default WPZIcon;
