import axiosInstance from '@/lib/helpers/axiosInstance';
import convertBlob from '@/lib/helpers/convertBlob';
import workplazeLogo from 'assets/img/logo/workplaze-logo-5.png';

const getClientLogo = async (): Promise<string | null> => {
  try {
    const response = await axiosInstance.get(
      `v1/hrm/common/public/get/client-logo`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      }
    );
    const blob = response.data as Blob;

    if (blob.size !== 0) {
      const imageSrc = convertBlob(response.data);
      return imageSrc;
    }
    return workplazeLogo;
  } catch (error) {
    console.error(error);
    return workplazeLogo;
  }
};

export default getClientLogo;
