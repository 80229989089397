import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useResourceKey } from '@/lib/hooks/useResourceKey';
import useSwitchLanguage from '@/hooks/useSwitchLanguage';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

type LanguageData = {
    code: string;
    iconflag: string;
    label: string;
};

const DEFAULT_LANG: LanguageData[] = [
    {
        code: 'en',
        iconflag: 'en',
        label: 'English',
    },
    // {
    //     iconflag: 'id',
    //     code: 'id',
    //     label: 'Indonesian',
    // },
    {
        iconflag: 'th',
        code: 'th',
        label: 'Thailand',
    },
];

type LanguageSelectorProps = {
    withBorder?: boolean;
    languages?: LanguageData[] | null;
    lang?: string;
};
const LanguageSelector: React.FC<LanguageSelectorProps> = ({ languages = [], withBorder = true, lang = '' }) => {
    const {
        i18n: { language },
    } = useTranslation();
    const { setLanguageToLocalStorage } = useResourceKey();
    const { switchContainerLanguage } = useSwitchLanguage();

    const handleLanguageChange = (value: string) => {
        switchContainerLanguage(value);
        setLanguageToLocalStorage(value);
    };

    useEffect(() => {
        if (lang) {
            switchContainerLanguage(lang);
            setLanguageToLocalStorage(lang);
        }
    }, [lang]);

    return (
        <div className="login-language-selector" style={{ position: 'relative' }}>
            <Select
                defaultValue={language}
                style={{
                    color: '#2D3A4F',
                    fontSize: 18,
                    lineHeight: 22,
                    width: 160,
                }}
                labelRender={(value) => <span>{value.value.toString().toUpperCase()}</span>}
                onChange={handleLanguageChange}
                suffixIcon={null}
                popupMatchSelectWidth={false}
            >
                {((languages?.length && languages) || DEFAULT_LANG).map((lang) => (
                    <Select.Option key={lang.code} value={lang.code}>
                        {`${lang.label} (${lang.code.toUpperCase()})`}
                    </Select.Option>
                ))}
            </Select>
            {!withBorder && (
                <DownOutlined
                    style={{
                        opacity: 0.5,
                        position: 'absolute',
                        top: '30%',
                        right: '5%',
                    }}
                />
            )}
        </div>
    );
};

export default LanguageSelector;
