import { FormLoginValue, LoginData } from '../types/loginType';
import React, { useState } from 'react';

import { DATE_TIME_SEC_YYYY_MM_DD_DASH } from '@/lib/helpers/constant';
import { Form } from 'antd';
import LoginFormContext from './LoginFormContext';
import dayjs from 'dayjs';
import useCookies from '@/lib/hooks/useCookies';

interface Props {
    children: React.ReactNode;
}

const LoginFormContextProvider: React.FC<Props> = ({ children }) => {
    const { createCookies } = useCookies();
    const [form] = Form.useForm();
    const [blockerToken, setBlockerToken] = useState<string | null>(null);
    const [isForceChangePassword, setIsForceChangePassword] = useState<boolean>(false);
    const [isAcceptancePolicy, setIsAcceptancePolicy] = useState<boolean>(false);

    const onLoginSuccess = (loginResponse: LoginData, accountName: FormLoginValue) => {
        const tokenExpired: string = dayjs.unix(loginResponse.accessTokenExpired).format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
        const refreshTokenExpired: string = dayjs.unix(loginResponse.refreshTokenExpired).format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
        const current: string = dayjs().utc(true).format(DATE_TIME_SEC_YYYY_MM_DD_DASH);
        const expired: number = dayjs(tokenExpired).diff(current, 'day', true);
        const expiredRefresh: number = dayjs(refreshTokenExpired).diff(current, 'day', true);

        createCookies(
            'jwtToken',
            {
                token: loginResponse.accessToken,
                fssid: loginResponse.fssid,
            },
            true,
            { expires: expired }
        );

        createCookies(
            'manage',
            {
                expired: loginResponse.accessTokenExpired,
                intervalDuration: loginResponse.intervalDuration,
                username: accountName.username,
                password: accountName.password,
                isRemember: accountName.isRemember ? accountName.isRemember : false,
            },
            true
        );

        createCookies(
            'refreshToken',
            {
                refreshToken: loginResponse.refreshToken,
            },
            true,
            { expires: expiredRefresh, secure: true }
        );

        createCookies('main', {
            currentOU: loginResponse.currentOU,
            currentRole: loginResponse.currentRole,
            loginResult: {
                languageList: ['en', 'id', 'th'],
            },
            pinType: loginResponse.pinType,
            isForceCreatePin: loginResponse.isForceCreatePin, //true,
        });
    };

    return (
        <LoginFormContext.Provider
            value={{
                isLoginLoading: false,
                onLoginSuccess: onLoginSuccess,
                form: form,
                blockerToken: blockerToken,
                setBlockerToken: setBlockerToken,
                isAcceptancePolicy: isAcceptancePolicy,
                setIsAcceptancePolicy: setIsAcceptancePolicy,
            }}
        >
            {children}
        </LoginFormContext.Provider>
    );
};

export default React.memo(LoginFormContextProvider);
