import dayjs, { Dayjs } from 'dayjs';

import { ModeAccess } from '../interfaces';

export const LANG_CODE = () => localStorage.getItem('language') || 'en';

export const SF7_DEV_HOST = 'sf7dev.dataon.com';
export const SF7_QA_HOST = 'sf7qa.dataon.com';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_DEV_OR_TESTING_ENV =
  IS_DEV ||
  window.location.host === SF7_DEV_HOST ||
  window.location.host === SF7_QA_HOST;

export const getLocalTemp = () => {
  const languser = LANG_CODE() || 'en';
  // const localeTemp = languser !== 'id' && languser !== 'th' ? 'en' : languser;
  return languser !== 'id' && languser !== 'th' ? 'en' : languser;
};

export const GO_API_LIST = [
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO,
  process.env.REACT_APP_ENDPOINT_DISCUSSION_GO,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_SUPERBANK,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_PTKAF,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_MOWILEX,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_IR88,
  process.env.REACT_APP_ENDPOINT_HMC_GO,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_ANTAM,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_INALUM,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_PTMI,
  process.env.REACT_APP_ENDPOINT_RESTAPI_GO_BNI_SEKURITAS,
];

export default {
  CUSTOMIST: 'CUSTOMIST',
  COMPANYNAME: 'COMPANYNAME',
  APP_NAME: 'app_name',
  COMPANY_LOGO: 'COMPANY_LOGO',
  INSTANCE: 'INSTANCE',
  REMEMBER_ME: '_r_me',
  CL_ACCNAME: 'cl_accname',
  CL_API_LC: 'cl_api_lc',
  CL_LANDINGPAGE: 'cl_landingpage',
  VERIF_IS_CONFIRMED: 'IS_CONFIRMED',
  VERIF_IS_ALREADY_CONFIRMED: 'IS_ALREADY_CONFIRMED',
  VERIF_IS_INVALID_CODE: 'IS_INVALID_CODE',
  LOGIN_CONFIG: 'login-config',
  USERS_LOGIN_CONFIG: 'users_logged_in',
  SECONDARY_INSTANCE_STR: '_secondary_',
  RESET_ACCOUNT_INSTANCES: 'RESET_ACCOUNT_INSTANCES',
  LANG_APP: 'lang-app',
  SESSION_EXPIRED_TIME: 'session_expired_time',
  C_DATA: '_c_data',
  C_DATA_JWT: '_c_data_jwt',
  EXPIRED_JWT_TIME: 'expired_jwt_time',
  TEMP_C_DATA: '_temp_data',
  TEMP_JWT: 'temp_jwt',
  TEMP_USER: 'temp_user',
  TEMP_TOKEN: 'temp_token',
  BLOCKER: 'blocker',
  MAIN: 'main',
  COID: 'COID',
  MIN_PASSWORD_LENGTH: 'min_pwd_length',
  RELOGIN: '__relogin__',
  RECENT_ACCOUNT: 'recentaccount',
  CHANGE_PASSWORD: 'changepassword',
  LOGIN_FORM_USERNAME: 'userName',
  LOGIN_FORM_PASSWORD: 'password',
  LOGIN_FORM_ACCNAME: 'accountName',
  LOGIN_FORM_ISREMEMBER: 'isRemember',
  AUTO_LOGIN: 'auto_login',
  CHANGE_PASS_TYPE: 'changePassType',
  VERIFY_ACCESS: 'verify_access',
  SF: 'sf',
  SAML: 'saml',
  OAUTH: 'oauth',
  SSO: 'sso',
  SSOKEY: 'ssokey',
  HCODE: 'hcode',
  SSO_CONFIG: 'SSO_CONFIG',
  WP_CUSTOM_THEME: 'WP_CUSTOM_THEME',
  QUERY_PARAMS: ['username', 'accname', 'pass'],
  PERSIST_ROOT: 'persist:root',
  HAS_PIN: 'HAS_PIN',
  HAS_DOCPASSWORD: 'HAS_DOCPASSWORD',
  IS_FORM_OPENED: 'IS_FORM_OPENED',
  INVALID_SERVICE: 'INVALID_SERVICE',
  SHOW_MODAL_RELOGIN: 'SHOW_MODAL_RELOGIN',
};

export const ROLE_USER = {
  SUPER_ADMIN: 9,
  ADMIN: 2,
  REGULAR: 1,
};

export const baseURL = ['localhost'].some((item) =>
  window.location.origin.includes(item)
)
  ? 'http://localhost:9090/'
  : process.env.REACT_APP_ENDPOINT_HMC_GO;
export const baseURLHRM = ['localhost'].some((item) =>
  window.location.origin.includes(item)
)
  ? process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ
  : process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ;

export const baseURLGateway = ['localhost'].some((item) =>
  window.location.origin.includes(item)
)
  ? 'http://localhost:8080/'
  : process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ;

export const TIME_DATE_ABBR = 'HH:mm:ss (ddd, DD MMM YYYY)';

export const DATE_TIME_YYYY_MM_DD_DASH = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_SEC_YYYY_MM_DD_DASH = 'YYYY-MM-DD HH:mm:ss';
export const DATE_YYYY_MM_DD_DASH = 'YYYY-MM-DD';
export const DATE_YYYY_MM_DASH = 'YYYY-MM';

export const DATE_TIME_DD_MM_YYYY_SLASH = 'DD/MM/YYYY HH:mm';
export const DATE_TIME_SEC_DD_MM_YYYY_SLASH = 'DD/MM/YYYY HH:mm:ss';
export const DATE_DD_MM_YYYY_SLASH = 'DD/MM/YYYY';
export const DATE_DD_MM_YY_SLASH = 'DD/MM/YY';
export const DATE_YYYY_MM_DD_SLASH = 'YYYY/MM/DD';
export const DATE_YYYY_MM_D_SLASH = 'YYYY/MM/D';

export const DATE_TIME_D_MMM_YYYY_SPACE = 'D MMM YYYY HH:mm';
export const DATE_DD_MM_YYYY_SPACE = 'DD MM YYYY';
export const DATE_D_MMMM_YYYY_SPACE = 'D MMMM YYYY';
export const DATE_DD_MMM_YYYY_SPACE = 'DD MMM YYYY';
export const MMM_YYYY_SPACE = 'MMM YYYY';
export const DATE_MONTH_YEAR_SPACE = 'D MMM YYYY';

export const DATE_TIME_ISO = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const DATE_TIME_ISO_SEC = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const DATE_TIME_ZERO_ISO = 'YYYY-MM-DD[T00:00:00Z]';
export const DATE_TIME_ZERO_ISO_SEC = 'YYYY-MM-DD[T00:00:00.000Z]';
export const DATE_TIME_ISO_PRESET = [
  'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  'YYYY-MM-DDTHH:mm:ss.SS[Z]',
  'YYYY-MM-DDTHH:mm:ss.S[Z]',
  'YYYY-MM-DDTHH:mm:ss[Z]',
];

export const TIME_HH_MM = 'HH:mm';
export const TIME_HH_MM_SS = 'HH:mm:ss';
export const DAY_MONTH_YEAR_DASH = 'DD-MM-YYYY';

export const YEAR_DOT_M = 'YYYY.M';
export const YEAR_DOT_MM = 'YYYY.MM';
export const YEAR_MONTH_SLASH = 'YYYY/MM';
export const YEAR_M_SLASH = 'YYYY/M';

export const YEAR_FORMAT = 'YYYY';
export const MONTH_FORMAT = 'MM';
export const MONTH_MMMMM_FORMAT = 'MMMM';
export const DAY_FORMAT = 'D';

export const MONTH_YEAR_SPACE = 'MMMM YYYY';
export const ABBR_MONTH_YEAR_SPACE = 'MMM YYYY';
export const DDD_SPACE_DD_MMM_YYYY = 'ddd, DD MMM YYYY';
export const DDD_SPACE_D_MMMM_YYYY = 'ddd, D MMMM YYYY';
export const DDD_SPACE_D_MMM_YYYY = 'ddd, D MMM YYYY';
export const DATE_TIME_DD_MM_YYYY_SLASH_COMMA = 'DD/MM/YYYY, HH:mm';
export const MM_DD_YYYY_SLASH = 'MM/DD/YYYY';
export const DATE_YYYY_MM_D = 'YYYYMMD';
export const DATE_YYYY_MM_DD = 'YYYYMMDD';
export const DATE_YYYY_MM_DD_HH_MM_SS_SSS = 'YYYYMMDDHHmmssSSS';

export const DATE_TIME_ISO_YYYY_MM_DD_DASH = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const MIN_DATE_TIME_ISO = '1900-01-01T00:00:00Z';
export const MIN_TIME = '00:00';

export const defaultModeAccess: ModeAccess = {
  new: false,
  edit: false,
  delete: false,
  view: false,
  print: false,
  submit: false,
  approved: false,
  reset: false,
  requestCancel: false,
  saveDraft: false,
  rejected: false,
  submitApp: false,
  cancel: false,
  revoke: false,
};

export const MINUTE = 60; // 60 seconds

export const ProcessStatusInit = 0;
export const ProcessStatusProcessing = 1;
export const ProcessStatusWarning = 2;
export const ProcessStatusSuccess = 3;
export const ProcessStatusFailure = 4;

export const Gender = {
  MALE: 'M',
  FEMALE: 'F',
};
