import { FormInstance } from 'antd';
import { ResponseEmailApproval } from '../types/emailApproval';
import { createContext, useContext } from 'react';

interface EmailApprovalContextType {
    form: FormInstance;
    onEmailApprovalSuccess: (response: ResponseEmailApproval) => void;
}

const contextInitialValue: EmailApprovalContextType = {
    form: {} as FormInstance,
    onEmailApprovalSuccess: (response) => {},
};

const EmailApprovalContext = createContext<EmailApprovalContextType>(contextInitialValue);

export default EmailApprovalContext;
