import { Col, Grid, Image, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { CustomLayoutStyled } from './CustomLayoutStyled';
import LanguageSelector from 'components/global/lang/LangSelector';
import getClientLogo from '@/lib/components/image-handler/getClientLogo';

type CustomLayoutProps = {
    children: JSX.Element;
};

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
    const screenBreakPoints = Grid.useBreakpoint(); // Get screen breakpoints for responsive design
    const [logo, setLogo] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchLogo = async () => {
            const logo = await getClientLogo();
            setLogo(logo);
            setLoading(false);
        };
        fetchLogo();
    }, []);

    return (
        <>
            <CustomLayoutStyled className="WP-login" {...screenBreakPoints}>
                {/* Header section with language selector */}
                <div className="WP-login__header">
                    <LanguageSelector withBorder={false} />
                </div>

                {/* Main container with logo and children components */}
                <div className="WP-login__container">
                    <Row justify="center" align="middle">
                        {/* Centered WorkplazeLogo */}
                        <Col span={24}>
                            <Row justify="center" style={{ marginBottom: 56 }}>
                                <Spin spinning={loading}>
                                    <Image preview={false} className="image company-logo" alt="" src={logo ?? ''} width={150} />
                                </Spin>
                            </Row>
                        </Col>
                        {/* Container body with children components */}
                        <Col span={24}>
                            <Spin spinning={loading}>
                                <div className="WP-login__container__body">{children}</div>
                            </Spin>
                        </Col>
                    </Row>
                </div>

                {/* Footer section with copyright text */}
                <div className="WP-login__footer">
                    <span className="WP-login__footer__text">{`©Workplaze™. All Rights Reserved ${new Date().getFullYear()}`}</span>
                </div>
            </CustomLayoutStyled>
        </>
    );
};

export default CustomLayout;
