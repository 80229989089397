import React, { useContext } from 'react';
import EmailApprovalContext from './EmailApprovalContext';
import { Form } from 'antd';

interface Props {
    children: React.ReactNode;
}

const EmailApprovalContextProvider: React.FC<Props> = ({ children }) => {
    const [form] = Form.useForm();

    const onEmailApprovalSuccess = (response: any) => {};

    return (
        <EmailApprovalContext.Provider
            value={{
                form: form,
                onEmailApprovalSuccess: onEmailApprovalSuccess,
            }}
        >
            {children}
        </EmailApprovalContext.Provider>
    );
};

export default EmailApprovalContextProvider;

export const useEmailApprovalContext = () => {
    const emailApprovalConsumer = useContext(EmailApprovalContext);
    if (!emailApprovalConsumer) {
        throw new Error('useEmailApprovalContext must be used within a EmailApprovalContextProvider');
    }
    return emailApprovalConsumer;
};
