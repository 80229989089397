import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export interface ProviderConfig {
    providerDescription: string;
    providerName: string;
    clientId: string;
    redirectUrl: string;
    scopes: string;
    authorizationEndPoint: string;
    tokenEndPoint: string;
    iconName?: string;
    openIdConfigEndpoint?: string | null;
}

const userManagerInstances: Record<string, UserManager> = {};

export const getUserManager = (config: ProviderConfig): UserManager => {
    if (!userManagerInstances[config.providerName]) {
        let authorityOrigin = config.authorizationEndPoint;
        if (config.openIdConfigEndpoint) {
            const authorityUrl = new URL(config.openIdConfigEndpoint);
            const wellKnownPath = '/.well-known/openid-configuration';
            if (authorityUrl.pathname.endsWith(wellKnownPath)) {
                authorityUrl.pathname = authorityUrl.pathname.slice(0, -wellKnownPath.length);
            }
            authorityOrigin = authorityUrl.href;
        }

        const settings: UserManagerSettings = {
            authority: authorityOrigin,
            client_id: config.clientId,
            redirect_uri: config.redirectUrl,
            response_type: 'code',
            scope: config.scopes,
            stateStore: new WebStorageStateStore({ store: window.sessionStorage }), // Persist state
            userStore: new WebStorageStateStore({ store: window.sessionStorage }), // Persist tokens
            loadUserInfo: true, // Load user info after authentication
            response_mode: 'query',
            filterProtocolClaims: true,
            automaticSilentRenew: true,
            includeIdTokenInSilentRenew: true,
            monitorSession: true,
            disablePKCE: true, // Enable PKCE
        };

        userManagerInstances[config.providerName] = new UserManager(settings);
    }
    return userManagerInstances[config.providerName];
};

export const loginWithProvider = async (providerConfig: ProviderConfig) => {
    const manager = getUserManager(providerConfig);
    const stateToPersist = {
        providerName: providerConfig.providerName,
        settings: providerConfig,
    };
    sessionStorage.setItem('oidcProviderState', JSON.stringify(stateToPersist));
    try {
        await manager.signinRedirect({ state: JSON.stringify(stateToPersist) });
    } catch (error) {
        console.error(`Login failed for ${providerConfig.providerName}`, error);
    }
};

export const handleCallback = async () => {
    const storedState = sessionStorage.getItem('oidcProviderState');
    let manager;
    if (storedState) {
        const { settings } = JSON.parse(storedState);
        const restoredManager = getUserManager(settings);
        manager = restoredManager;
    } else {
        throw new Error('No stored state found');
    }
    const state = new URLSearchParams(window.location.search).get('state');
    const oidcStateConfig = JSON.parse(sessionStorage.getItem(`oidc.${state}`) || '{}');
    if (state === oidcStateConfig?.id) {
        return { authCode: new URLSearchParams(window.location.search).get('code'), client_id: oidcStateConfig.client_id };
    }
    return { authCode: null, client_id: null };
};
