import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import LoginPinModal from '@/lib/components/security/login-pin/LoginPinModal';
import { PinType } from '@/lib/components/security/login-pin/utils/enum';
import { findInNestedArray } from '@/lib/helpers/findInNestedArray';
import useCookies from '@/lib/hooks/useCookies';

interface ProtectorProps {
    loadingCallback: (loading: boolean) => void;
    children: React.ReactNode;
}

const Protector: React.FC<ProtectorProps> = ({ loadingCallback, children }) => {
    const virtualPath = process.env.VITE_APP_VIRTUAL_PATH || '/';
    const menuList = JSON.parse(localStorage.getItem('menuList') || '[]');
    const { getMainCookie, deleteCookies, getCookies } = useCookies();
    const { pinType } = getMainCookie();
    const pinToken = getCookies('pinToken', true);
    const navigate = useNavigate();
    const location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingLocation, setPendingLocation] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const disableBlockerRef = useRef(false);

    // Function to determine if navigation should be interrupted
    const shouldInterrupt = (path: string) => {
        const targetSetting = findInNestedArray(menuList, 'path', path);
        return targetSetting?.isPin && targetSetting?.isPassword2 && pinType !== PinType.NotDefined;
    };

    // Function to determine if the navigation is a redirect
    const isRedirecting = (path: string) => {
        return path !== location.pathname;
    };

    // Blocker to intercept navigation attempts
    const blocker = useBlocker(({ nextLocation }) => {
        const interrupt = shouldInterrupt(nextLocation.pathname) && isRedirecting(nextLocation.pathname);
        if (disableBlockerRef.current) {
            disableBlockerRef.current = false;
            return false;
        }
        return interrupt;
    });

    // Effect to handle when navigation is blocked
    useEffect(() => {
        if (blocker.state === 'blocked') {
            setPendingLocation(blocker.location.pathname.slice(virtualPath.length));
            setIsModalOpen(true);
            deleteCookies('pinToken');
        }
    }, [blocker, virtualPath]);

    // Effect to handle direct URL access on initial load
    useEffect(() => {
        const currentPath = location.pathname;
        const isDirectAccess = window.history.length === 1;

        if (shouldInterrupt(currentPath) && (isDirectAccess || !pinToken)) {
            deleteCookies('pinToken');
            setIsLoading(true);
            setPendingLocation(currentPath);
            setIsModalOpen(true);
        }
    }, []); // Empty dependency array to run only on initial load

    // Function to handle completion of the PIN modal
    const handleComplete = (complete: boolean) => {
        if (complete) {
            localStorage.setItem('loginPinCompleted', 'true');
            setIsModalOpen(false);
            if (pendingLocation) {
                disableBlockerRef.current = true;
                navigate(pendingLocation);
            }
            setPendingLocation(null);
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    // Function to handle cancellation of the PIN modal
    const handleCancel = () => {
        if (window.history.length === 1) {
            setIsModalOpen(false);
            setPendingLocation(null);
            navigate('/standard/home');
        } else {
            setIsModalOpen(false);
            setPendingLocation(null);
        }
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    // Effect to call loadingCallback when modal state changes
    useEffect(() => {
        loadingCallback(isLoading);
    }, [isLoading, loadingCallback, isModalOpen]);

    return (
        <>
            <LoginPinModal
                isVisible={isModalOpen}
                onCancel={handleCancel}
                onResponse={(value) => handleComplete(value.isUnlocked)}
                type="Page"
                targetPath={pendingLocation || ''}
            />
            {children}
        </>
    );
};

export default Protector;
