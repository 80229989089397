import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense, useEffect } from 'react';

import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import LoginFormContextProvider from './pages/auth/login/contexts/LoginFormContextProvider';
import Routing from './Routing';
import SharedLibrary from 'lib/index';
import WPLoadingComponent from 'components/global/wp-loading-component';
import WPThemeSelector from './components/global/wp-theme-selector';
import reducers from './store/reducers';
import useGetMainCookie from './lib/hooks/useGetMainCookie';

function App() {
    const cookies = useGetMainCookie();
    const isLoadingLoginSFSSO = false;
    const isLoadingLoginSSO = false;
    const virtualPath = process.env.VITE_APP_VIRTUAL_PATH || '';

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    useEffect(() => {
        if (location.pathname === `${virtualPath}/OauthCallback`) {
            return;
        }
        if (location.pathname === `${virtualPath}/restricted`) {
            return;
        }
        if (location.pathname !== `${virtualPath}/auth` && !cookies) {
            window.location.href = `${virtualPath}/auth`;
        }
    }, []);

    return (
        <SharedLibrary module="container" customRedux={reducers}>
            <QueryClientProvider client={queryClient}>
                <ErrorBoundary>
                    <Suspense fallback={<WPLoadingComponent />}>
                        <WPThemeSelector>
                            {isLoadingLoginSFSSO || isLoadingLoginSSO ? (
                                <WPLoadingComponent loadingAuth={isLoadingLoginSFSSO || isLoadingLoginSSO} />
                            ) : (
                                <LoginFormContextProvider>
                                    <Routing cookies={cookies} />
                                </LoginFormContextProvider>
                            )}
                        </WPThemeSelector>
                    </Suspense>
                </ErrorBoundary>
            </QueryClientProvider>
        </SharedLibrary>
    );
}

export default App;
