import { Form, FormInstance, Select } from 'antd';
import React, { useEffect } from 'react';

import WPForm from '../../wp-form/WPForm';
import { useTranslate } from '@/lib/hooks/useResourceKey';

interface RequestOTPFormProps {
  formInstance: FormInstance;
  otpMethodOption: { method: string; methodValue: string; sendingBy: string }[];
  onMethodChange: (value: string) => void;
  onSubmit: (values: any) => void;
}

const RequestOTPForm: React.FC<RequestOTPFormProps> = ({
  formInstance,
  otpMethodOption,
  onMethodChange,
  onSubmit,
}) => {
  const labelPleaseChooseMethod = useTranslate(
    'PleaseChooseMethod',
    'Please Choose Method'
  );
  const labelSendOTPto = useTranslate('FPSendOTPto', 'Please enter Send OTP to');
  const options = otpMethodOption?.map((option) => ({
    label: option.methodValue,
    value: option.sendingBy,
  }));

  useEffect(() => {
    if (options[0]?.value) {
      onMethodChange(options[0].value);
      formInstance.setFieldsValue({ method: options[0].value });
    }
  }, [options, onMethodChange, formInstance]);

  return (
    <WPForm form={formInstance} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="method"
        label={labelSendOTPto}
        layout="vertical"
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          optionLabelProp="label"
          placeholder={labelPleaseChooseMethod}
          options={options}
          onChange={onMethodChange}
        />
      </Form.Item>
    </WPForm>
  );
};

export default RequestOTPForm;
