import EmailApproval from './component/EmailApproval';
import EmailApprovalContextProvider from './context/EmailApprovalContextProvider';

const EmailApprovalPage = () => {
    return (
        <EmailApprovalContextProvider>
            <EmailApproval />
        </EmailApprovalContextProvider>
    );
};
export default EmailApprovalPage;
