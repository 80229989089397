import localforage from 'localforage';
import axiosInstance from '../helpers/axiosInstance';
import { DATE_YYYY_MM_DD_DASH } from '../helpers/constant';
import { useTranslation } from 'react-i18next';
import { decrypt, encrypt } from '../helpers/cryptography';
import { BaseResponse, ResourceKey, ResourceKeyResponse } from '../interfaces';
import dayjs from 'dayjs';
import cookies from './useCookies';

const apiGetResourceKey = `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/common/get/metadata/all-resource-key`;
const apiGetResourceKeyPlublic = `${process.env.VITE_APP_ENDPOINT_MY_WORKPLAZ}v1/hrm/common/public/get/resource-key`;

export const useResourceKey = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const isExpiredResourceKey = () => {
    const expiredDate = getExpiredResourceKey();
    return dayjs().unix() > expiredDate;
  };

  const getExpiredResourceKey = () => {
    const objEncrypt = localStorage.getItem('hmc_resource');
    return decrypt({ value: objEncrypt })?.expiredDate as number;
  };

  function getCachingDate(): Record<string, string>;
  function getCachingDate(language: string): string | null;
  function getCachingDate(language?: string) {
    const objEncrypt = localStorage.getItem('hmc_resource');
    const objDecrypt = decrypt({
      value: objEncrypt,
    }) as unknown as Record<string, string>;
    const cachingDate = language ? objDecrypt?.[language] || null : objDecrypt;

    return cachingDate;
  }

  const getResourceKeys = async () => {
    try {
      const db = localforage.createInstance({
        name: `resource-description`,
      });
      return await db.getItem<Record<string, string>>(language);
    } catch {
      return null;
    }
  };

  const dropResourceKey = async () => {
    try {
      await localforage.dropInstance({
        name: `resource-description`,
      });
    } catch (error) {
      console.error('Failed to drop resource keys', error);
    }
  };

  const transformResourceKey = (data: ResourceKey[]) => {
    return data.reduce((acc, item) => {
      acc[item.key.toUpperCase()] = item.description;
      return acc;
    }, {} as Record<string, string>);
  };

  const fetchResourceKey = async (key: string[] = []) => {
    try {
      const { getCookies } = cookies();
      const tokenCookie = getCookies('jwtToken', true);
      const { data } = await axiosInstance.post<
        BaseResponse<ResourceKeyResponse[]>
      >(tokenCookie ? apiGetResourceKey : apiGetResourceKeyPlublic, {
        cachingDate: tokenCookie ? getCachingDate(language) : null,
        key,
        cultureName: language,
      });

      let resourceList = {};

      if (Array.isArray(data?.DATA?.[0]?.resourceKeyList)) {
        resourceList = transformResourceKey(data.DATA[0].resourceKeyList);

        onSuccess(
          resourceList,
          data.DATA[0].expiredDate,
          tokenCookie ? true : false
        );
      }

      return resourceList;
    } catch (error) {
      console.error(error);
    }
  };

  const onSuccess = async (
    data: Record<string, string>,
    expiredDate: number,
    isLogin: boolean = false
  ) => {
    try {
      const db = localforage.createInstance({
        name: `resource-description`,
      });
      const resourceKeys = (await db.getItem(language)) as unknown as Record<
        string,
        string
      >;
      db.setItem(language, { ...resourceKeys, ...data });
      if (isLogin) {
        const cachingDate = getCachingDate();
        const obj = {
          ...cachingDate,
          [language]: dayjs().format(DATE_YYYY_MM_DD_DASH),
          expiredDate: expiredDate,
        };
        const objEncrypt = encrypt({ value: obj });
        localStorage.setItem('hmc_resource', objEncrypt);
      }
    } catch (error) {
      console.error('Failed to save resource keys', error);
    }
  };

  const setLanguageToLocalStorage = (language: string) =>
    localStorage.setItem('language', language);

  const getLanguageFromLocalStorage = () => localStorage.getItem('language');

  const translate = (
    key: string,
    defaultValue?: string,
    interpolation?: Record<string, string>
  ) => {
    if (key) {
      const keyUpper = key.toString().toLocaleUpperCase();
      return t(keyUpper, {
        defaultValue: defaultValue || key,
        interpolation,
      });
    }
    return '';
  };

  return {
    fetchResourceKey,
    getResourceKeys,
    dropResourceKey,
    isExpiredResourceKey,
    setLanguageToLocalStorage,
    getLanguageFromLocalStorage,
    translate,
  };
};

export const useTranslate = (
  key: string,
  defaultValue?: string,
  interpolation?: Record<string, string>
) => {
  const { t } = useTranslation();

  return t(key.toLocaleUpperCase(), {
    defaultValue: defaultValue || key,
    interpolation,
  });
};
