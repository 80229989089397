import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { BaseErrorResponse, BaseResponse } from '../../../interfaces';
import { Flex, Form, Input, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import ChangePinForm from './ChangePinForm';
import { LockSVG } from '../../dynamic-icon';
import { LoginPinContext } from '../login-pin/context/LoginPinContext';
import { PasswordValidationSetting } from '../password-validator/PasswordValidator';
import RequestOTPForm from './RequestOTPForm';
import SecurityModal from '../security-modal';
import ShowToastNotificationResult from '../../show-toast-notification-result/ShowToastNotificationResult';
import SubmitOTPForm from './SubmitOTPForm';
import WPForm from '../../wp-form/WPForm';
import axiosInstance from '../../../helpers/axiosInstance';
import changePasswordImage from '@/lib/assets/img/wp-change-pass-illustration.png';
import useCookies from '@/lib/hooks/useCookies';
import useGetMainCookie from '@/lib/hooks/useGetMainCookie';
import { useTranslate } from '../../../hooks/useResourceKey';

enum Step {
  RequestOTP = 1,
  SubmitOTP = 2,
  ChangePIN = 3,
}

interface ResponseOTP {
  referCode: string;
  otpExpiredTime: number;
  resentOTPTime: number;
}

interface ResponseMethod {
  sendingBy: string[];
  method: string[];
  methodValue: string[];
  token: string;
}

interface MethodOption {
  sendingBy: string;
  method: string;
  methodValue: string;
}

interface ResponseChangePin {
  messageCode: string;
  description: string;
  messageType: string;
}

const ForgotPinModal: React.FC = () => {
  const { getCookies } = useCookies();
  const cookies = getCookies('manage', true);
  const mainCookies = useGetMainCookie();

  const { isForgotPinVisible, setIsForgotPinVisible } =
    useContext(LoginPinContext);

  const [submitOTPErrorCount, setSubmitOTPErrorCount] = useState<number>(0);

  const labelUserId = useTranslate('UserID', 'User ID');
  const labelChangePIN = useTranslate('Save', 'Save');
  const labelCancel = useTranslate('Cancel', 'Cancel');
  const labelSubmit = useTranslate('Submit', 'Submit');
  const labelForgotPIN = useTranslate('ForgotPIN', 'Forgot PIN');

  const labelError = useTranslate('Error', 'Error');
  const labelNext = useTranslate('Next', 'Next');
  const labelFailedToSendOTP = useTranslate(
    'FailedToSendOTP',
    'Failed to send OTP'
  );
  const labelOTPHasBeenSent = useTranslate('OTPsent', 'OTP has been sent');

  const labelFailedFetchOTPMethod = useTranslate(
    'FailedFetchOTPMethod',
    'Failed to fetch OTP method'
  );
  const labelFailedToSubmitOTP = useTranslate(
    'FailedToSubmitOTP',
    'Failed to submit OTP'
  );
  const labelSuccess = useTranslate('Success', 'Success');
  const labelChangePinSuccess = useTranslate(
    'ChangePinSuccess',
    'Your PIN has been change successfully'
  );
  const labelFailedToChangePin = useTranslate(
    'FailedToChangePin',
    'Failed to change PIN'
  );
  const labelInvalidOTP = useTranslate('InvalidOTP', 'Invalid OTP');
  const labelOverDueSubmit = useTranslate(
    'InvalidSubmitOTPOverDue',
    'submit invalid OTP over 3 times please resend OTP and try again'
  );
  const labelPleaseWaitAFewMinute = useTranslate(
    'Waitforfewminutes',
    'Please wait for a few minutes and try again'
  );
  // State variables
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(Step.RequestOTP);
  const [methodOptions, setMethodOptions] = useState<MethodOption[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<string>('');
  const [refNo, setRefNo] = useState<string>('-');
  const [expireIn, setExpireIn] = useState<number>(0);
  const [resendOTPTime, setResendOTPTime] = useState<number>(0);

  const [passwordValidationSetting, setPasswordValidationSetting] =
    useState<PasswordValidationSetting>();

  const [formUserID] = Form.useForm();
  const [formRequestOTP] = Form.useForm();
  const [formSubmitOTP] = Form.useForm();
  const [formChangePIN] = Form.useForm();

  useEffect(() => {
    if (isForgotPinVisible) getOTPMethod();
  }, [isForgotPinVisible]);

  // Fetch OTP methods
  const getOTPMethod = async () => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<BaseResponse<ResponseMethod>> =
        await axiosInstance.get(`v1/hrm/security/forgot-pin/otp-method`);

      const options: MethodOption[] = response.data.DATA.method.map(
        (method, index) => ({
          methodValue: response.data.DATA.methodValue[index],
          method,
          sendingBy: response.data.DATA.sendingBy[index],
        })
      );
      setMethodOptions(options);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ShowToastNotificationResult({
        type: 'error',
        title: labelError,
        subtitle: labelFailedFetchOTPMethod,
        autoClose: true,
        autoCloseSecond: 3,
      });
    }
  };

  // Handle method change
  const onMethodChange = (value) => {
    setSelectedMethod(value);
  };

  // Request OTP
  const requestOTP = async (values: { method: string }) => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<BaseResponse<ResponseOTP>> =
        await axiosInstance.get(
          `v1/hrm/security/forgot-pin/otp?sendingBy=${values.method}`
        );
      setExpireIn(0); // Reset expire time to trigger reset timer in OTPInput component
      setResendOTPTime(0); // Reset expire time to trigger reset timer in OTPInput component
      // Introduce a slight delay to ensure state update
      setTimeout(() => {
        setExpireIn(response.data.DATA.otpExpiredTime);
        setResendOTPTime(response.data.DATA.resentOTPTime);
      }, 0);
      setRefNo(response.data.DATA.referCode);
      setIsLoading(false);
      setCurrentStep(Step.SubmitOTP);
      ShowToastNotificationResult({
        type: 'success',
        title: labelSuccess,
        subtitle: labelOTPHasBeenSent,
        autoClose: true,
        autoCloseSecond: 3,
      });
    } catch (e: any) {
      const error = e as BaseErrorResponse;
      setIsLoading(false);
      if (error.HSTATUS === HttpStatusCode.TooManyRequests) {
        ShowToastNotificationResult({
          type: 'error',
          title: labelError,
          subtitle: labelPleaseWaitAFewMinute,
          autoClose: true,
          autoCloseSecond: 3,
        });
      } else {
        ShowToastNotificationResult({
          type: 'error',
          title: labelError,
          subtitle: error.MESSAGE,
          autoClose: true,
          autoCloseSecond: 3,
        });
      }
    }
  };

  // Submit OTP
  const submitOTP = async (values: { otp: string }) => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<BaseResponse<PasswordValidationSetting>> =
        await axiosInstance.post(`v1/hrm/security/forgot-pin/otp`, {
          tokenParam: values.otp,
          referCode: refNo,
        });
      setPasswordValidationSetting(response.data.DATA);
      setIsLoading(false);
      setCurrentStep(Step.ChangePIN);
    } catch (e: any) {
      setIsLoading(false);
      const error = e as BaseErrorResponse;

      if (error.HSTATUS === HttpStatusCode.Unauthorized) {
        setSubmitOTPErrorCount(submitOTPErrorCount + 1);
        formSubmitOTP.resetFields(['otp']);
        formSubmitOTP.setFields([
          {
            name: 'otp', // Ensure this matches the form item name
            errors: [labelInvalidOTP],
          },
        ]);
        if (submitOTPErrorCount >= 3) {
          setSubmitOTPErrorCount(0);
          ShowToastNotificationResult({
            type: 'error',
            title: labelError,
            subtitle: labelOverDueSubmit,
            autoClose: true,
            autoCloseSecond: 3,
          });
        }
      } else {
        ShowToastNotificationResult({
          type: 'error',
          title: labelError,
          subtitle: error.MESSAGE,
          autoClose: true,
          autoCloseSecond: 3,
        });
      }
    }
  };

  // Submit new PIN
  const submitChangePin = async (values: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<BaseResponse<ResponseChangePin>> =
        await axiosInstance.post(`v1/hrm/security/forgot-pin/pin`, {
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        });
      onCancel();
      setIsLoading(false);
      ShowToastNotificationResult({
        type: 'success',
        title: labelSuccess,
        subtitle: labelChangePinSuccess,
        autoClose: true,
        autoCloseSecond: 3,
      });
    } catch (error) {
      setIsLoading(false);
      ShowToastNotificationResult({
        type: 'error',
        title: labelError,
        subtitle: labelFailedToChangePin,
        autoClose: true,
        autoCloseSecond: 3,
      });
    }
  };

  // Handle form submission
  const onSubmit = () => {
    switch (currentStep) {
      case Step.RequestOTP:
        formRequestOTP.submit();
        break;
      case Step.SubmitOTP:
        formSubmitOTP.submit();
        break;
      case Step.ChangePIN:
        formChangePIN.submit();
        break;
    }
  };

  // Handle modal cancel
  const onCancel = () => {
    formRequestOTP.resetFields();
    formSubmitOTP.resetFields();
    formChangePIN.resetFields();
    setCurrentStep(Step.RequestOTP);
    setIsForgotPinVisible(false);
  };

  return (
    <>
      <SecurityModal
        isVisible={isForgotPinVisible}
        onSubmit={onSubmit}
        submitButtonText={
          currentStep === Step.RequestOTP
            ? labelNext
            : currentStep === Step.SubmitOTP
            ? labelSubmit
            : currentStep === Step.ChangePIN
            ? labelChangePIN
            : ''
        }
        title={
          <Flex align="middle" gap={'small'}>
            <LockSVG /> <Typography>{labelForgotPIN}</Typography>
          </Flex>
        }
        isCloseable={true}
        onCancel={onCancel}
        isSubmitLoading={isLoading}
        imageUrl={changePasswordImage}
      >
        <WPForm form={formUserID} layout="vertical">
          <Form.Item label={labelUserId} layout="vertical" required>
            <Input
              disabled
              value={
                cookies.username || mainCookies.employeeProfile?.[0].employeeID
              }
            />
          </Form.Item>
        </WPForm>
        {currentStep === Step.RequestOTP && (
          <RequestOTPForm
            formInstance={formRequestOTP}
            otpMethodOption={methodOptions}
            onMethodChange={(value) => {
              onMethodChange(value);
            }}
            onSubmit={(value) => requestOTP(value)}
          />
        )}

        {currentStep === Step.SubmitOTP && (
          <SubmitOTPForm
            formInstance={formSubmitOTP}
            refNo={refNo || '-'}
            requestOTP={() => {
              requestOTP({ method: selectedMethod });
            }}
            resendOTPTime={resendOTPTime}
            expireIn={expireIn}
            onSubmit={submitOTP}
          />
        )}

        {currentStep === Step.ChangePIN && (
          <ChangePinForm
            formInstance={formChangePIN}
            passwordValidationSetting={
              passwordValidationSetting || ({} as PasswordValidationSetting)
            }
            onSubmit={submitChangePin}
          />
        )}
      </SecurityModal>
    </>
  );
};

export default ForgotPinModal;
