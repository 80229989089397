import { List } from 'antd';
import React from 'react';
import WPZIcon from '../../wpz-icon/WPZIcon';
import { useTranslate } from '@/lib/hooks/useResourceKey';

export interface PasswordValidationSetting {
  upperCharacter: string;
  lowerCharacter: string;
  numericCharacter: string;
  specialCharacter: string;
  hasUpper: boolean;
  hasLower: boolean;
  hasNumeric: boolean;
  hasSpecial: boolean;
  passwordMinLength: string;
  passwordMaxLength: string;
}

interface PasswordValidatorProps {
  password: string;
  validationRules: PasswordValidationSetting;
  touched: boolean;
  type?: 'Password' | 'PIN';
}

const PasswordValidator: React.FC<PasswordValidatorProps> = ({
  password,
  validationRules,
  touched,
  type = 'Password',
}) => {
  const labelHasUpper = useTranslate(
    'Uppercaseletters',
    'Uppercase letters (A-Z)'
  );
  const labelHasLower = useTranslate(
    'Lowercaseletters',
    'Lowercase letters (a-z)'
  );
  const labelHasNumeric = useTranslate('Numbers', 'Numbers (0-9)');
  const labelHasSpecial = useTranslate(
    'Specialcharacters',
    'Special characters'
  );

  const labelAtLeast = useTranslate('Atleast', 'At least');
  const labelAtlength = useTranslate('Atlength', 'At length');
  const labelExactly = useTranslate('Exactly', 'Exactly');
  const labelCharacters = useTranslate('Characters', 'characters');
  const labelPasswordMustContain = useTranslate(
    'PwdMustIncluded',
    'Password must contain'
  );
  const labelPINMustContain = useTranslate(
    'PINmustcontain',
    'PIN must contain'
  );

  if (!validationRules) return null; // Do not render until validation rules are loaded

  const minLength = parseInt(validationRules.passwordMinLength || '0', 10);
  const maxLength = parseInt(validationRules.passwordMaxLength || '0', 10);

  const isValidLength =
    password.length >= minLength && password.length <= maxLength;
  const hasUpper = new RegExp(`[${validationRules.upperCharacter}]`).test(
    password
  );
  const hasLower = new RegExp(`[${validationRules.lowerCharacter}]`).test(
    password
  );
  const hasNumeric = new RegExp(`[${validationRules.numericCharacter}]`).test(
    password
  );
  const hasSpecial = new RegExp(`[${validationRules.specialCharacter}]`).test(
    password
  );

  // If min and max length are the same, show "Exactly X characters", otherwise "At length X-Y characters"
  const lengthLabel =
    minLength === maxLength && minLength > 0 && maxLength > 0
      ? `${labelExactly} ${minLength} ${labelCharacters}`
      : minLength > 0 && maxLength > 0
      ? `${labelAtlength} ${minLength}-${maxLength} ${labelCharacters}`
      : minLength > 0
      ? `${labelAtLeast} ${minLength} ${labelCharacters}`
      : '';

  const dataSource = [
    {
      text: lengthLabel,
      valid: isValidLength,
    },
    validationRules.hasUpper && {
      text: labelHasUpper,
      valid: hasUpper,
      show: type === 'Password',
    },
    validationRules.hasLower && {
      text: labelHasLower,
      valid: hasLower,
      show: type === 'Password',
    },
    validationRules.hasNumeric && {
      text: labelHasNumeric,
      valid: hasNumeric,
    },
    validationRules.hasSpecial && {
      text: `${labelHasSpecial} (${validationRules.specialCharacter})`,
      valid: hasSpecial,
      show: type === 'Password',
    },
  ].filter((item) => item && item.show !== false); // Filter out items not to be shown

  return touched ? (
    <div>
      <p style={{ fontWeight: 'bold' }}>
        {type === 'PIN'
          ? `${labelPINMustContain}:`
          : `${labelPasswordMustContain}:`}
      </p>
      <List
        size="small"
        dataSource={dataSource}
        renderItem={(item) => (
          <List.Item
            style={{
              border: 'none',
              display: 'flex',
              padding: 0,
            }}
          >
            <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
              <WPZIcon
                iconName={
                  item && item.valid ? 'icon-x16Check' : 'icon-x16Close'
                }
                style={{
                  color: item && item.valid ? 'green' : 'red',
                  marginRight: '4px',
                }}
              />
              <span>{item && item.text}</span>
            </div>
          </List.Item>
        )}
      />
    </div>
  ) : null;
};

export default PasswordValidator;
