import { ConfigProviderProps } from 'antd';

const themeToken = {
  primary: '#395ebe',
  error: '#ff4d4f',
  boxShadow: 'var(--drop-shadow)',
  boxShadowLG:
    '0 3px 6px -4px rgba(168, 216, 243, 0.1), 0 6px 16px 0 rgba(168, 216, 243, 0.19), 0 9px 28px 8px rgba(168, 216, 243, 0.17)',
};

export const customizeTheme: ConfigProviderProps['theme'] = {
  token: {
    colorText: 'var(--character-title-85, rgba(0, 0, 0, 0.85))',
    fontFamily: `var(--font-family), var(--font-family-th), var(--font-family-ch), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important`,
    colorPrimary: themeToken.primary,
    colorError: themeToken.error,
    colorLink: themeToken.primary,
  },
  components: {
    Button: {
      paddingBlock: 5,
      paddingInline: 16,
      borderRadius: 16,
      boxShadow: 'var(--drop-shadow)',

      colorPrimaryBg: 'var(--primary-color)',
      colorPrimaryHover: 'var(--ant-primary-color-hover)',
      colorPrimaryActive: 'var(--ant-primary-color-active)',
      primaryShadow: 'var(--drop-shadow)',

      colorErrorBgActive: 'var(--ant-error-color-active)',
      dangerShadow: 'var(--drop-shadow)',
    },
    Divider: {
      colorSplit: 'rgba(0, 0, 0, 0.06)',
    },
    Layout: {
      headerBg: 'transparent',
      bodyBg: 'transparent',
      siderBg: 'transparent',
      footerBg: 'transparent',
      triggerBg: 'transparent',
    },
    DatePicker: {
      borderRadius: 6,
      boxShadowSecondary: themeToken.boxShadowLG,
    },
    Input: {
      borderRadius: 6,
    },
    InputNumber: {
      borderRadius: 6,
    },
    Mentions: {
      borderRadius: 6,
    },
    Radio: {
      borderRadius: 6,
    },
    Select: {
      borderRadius: 6,
    },
    TreeSelect: {
      borderRadius: 6,
    },
    Popover: {
      boxShadowSecondary: themeToken.boxShadowLG,
    },
    Modal: {
      boxShadow: themeToken.boxShadowLG,
    },
    Alert: {
      defaultPadding: '8px 15px',
      withDescriptionPadding: '15px 15px 15px 24px',
    },
    Card: {
      borderRadiusLG: 16,
    },
  },
};
